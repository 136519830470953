import React,{Component} from 'react';
import './phoneChange.css';
import serverIp from '../../../Functions/serverConfig';
import './deleteAcct.css'
import jwt_decode from 'jwt-decode';

class DeleteAcct extends Component{
    constructor(props){
        super(props);
        this.state={
            emailToDeleteInput:'',
            decodedUsername:'',
            deleteStatus:''
        }
    }

    componentDidMount(){
        this.processToken()
    }

    processToken(){
        let token = localStorage.getItem('token');
            // Check if the token is not found

        if (!token) {
            token = sessionStorage.getItem('token');
        }

        const decodedToken = jwt_decode(token);

        this.setState({decodedUsername:decodedToken.username});
    }

    sendAccountDelete(){
        if(this.state.decodedUsername===this.state.emailToDeleteInput){
            let token = localStorage.getItem('token');
            // Check if the token is not found
            if (!token) {token = sessionStorage.getItem('token');}
            ////////////////////////////////////////////////////
            let url = `${serverIp}/api/deleteAccount`;
            fetch(url,{
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                  'Content-Type': 'application/json',
                  // Include the token in the Authorization header
                  'Authorization': `Bearer ${token}`,
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer",
                // referrer:"https://catastro.crimpr.net/cdprpc/",
                // referrerPolicy:"no-referrer-when-downgrade",
                body: JSON.stringify({'emailToDelete':this.state.emailToDeleteInput}), // body data type must match "Content-Type"
              })
              .then(res=>res.json())
              .then((data)=>{
                console.log(data)
                if(data.deleteStatus==="Deleted"){
                    localStorage.removeItem('token');
                    sessionStorage.removeItem('token');
                    window.location.reload();
                }
                else{
                    this.setState({deleteStatus:"Algo sucedio en nuestro lado, favor tratar de nuevo o tratar mas tarde."})
                }
              })
        }
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
      }

    render(){
        return(
        <section className='deleteArea'>
            <div className='title'>{this.languageIndexer("Eliminar Cuenta",'Delete Account')}</div>
            <div className='deleteDisclaimer'>
                {this.languageIndexer("Cuentas eliminadas seran completamente perdidas acompañadas de membresias pagadas.",'Deleted accounts will be permanently lost, along with any associated paid memberships.')}
                <strong>{this.languageIndexer("Para proceder a eliminar la cuenta favor entrar email usado para registrar esta cuenta.",'To proceed with deleting the account, please enter the email used to register this account.')}&nbsp;</strong>
                <input onChange={(e)=>this.setState({emailToDeleteInput:e.target.value})} id='emailDeleteInput'></input>
            </div>
            <div style={{width:200,marginBottom:20,marginTop:-10,color:'red'}}>{this.state.deleteStatus}</div>
            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',width:170,marginTop:-15}}>
                <button onClick={()=>this.sendAccountDelete()} className="deleteAcctBtn">{this.languageIndexer("Eliminar Cuenta",'Delete Account')}</button>
                <button onClick={()=>this.props.closeDeleteAcct()} className="deleteAcctBtn">{this.languageIndexer("Atras",'Close')}</button>
            </div>
        </section>
        )
    }
}

export default DeleteAcct;