import React,{Component} from 'react';
import './cardForm.css';
import serverIp from '../../Functions/serverConfig';
import {
  CardElement,
  ElementsConsumer,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Paypalbutton from './paypalButton';
import logo from '../../images/stripe-payment-logo.png';
import paymentConfig from '../../Functions/paymentVariables';
import sendAnalytics from '../../Functions/analytics';
// Load your Stripe publishable key
const stripePromise = loadStripe(paymentConfig.stripePublishableKey);

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#303238',
        fontSize: '16px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#8395a7',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  };
  

class CheckoutForm extends Component {
  
  constructor(props){
    super(props);
    this.state={transactionMessage:'',suscriptionPrice:paymentConfig.plusPrice,transactionState:"Done"}
  }


  handleSubmit = async (event) => {
    window.fbq('track', 'Purchase', {value: 5.00, currency: 'USD'});
    this.setState({transactionState:"in-process"})
    event.preventDefault();
    const { stripe, elements } = this.props;
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
  
    let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    if (!token) {
      token = '';
    }
  
    const cardElement = elements.getElement(CardElement);
  
    try {
      // Create the subscription
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
  
      if (!paymentMethod) {
        this.setState({transactionState:"Done"})
        throw new Error('Payment method could not be created.');
      }
  
      // Create the subscription using the payment method
      const response = await fetch(`${serverIp}/api/processCardInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          subscriptionPlanId: paymentConfig.stripePlusSubscriptionPlanID,
          'chosenLevel':'plus',
          // Include other subscription-related data as needed
        }),
      });
  
      if (!response.ok) {
        this.setState({transactionState:"Done"})
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();

      //////////////////////
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        sessionStorage.setItem('token', data.newToken);
        if (sessionStorage.getItem('token') === null) {
          sessionStorage.setItem('token', data.newToken);
          }
        window.location.reload();
        this.setState({transactionState:"Done"})


  
      window.location.reload();
    } catch (error) {
      this.setState({ transactionMessage: error.message });
    }
  };

  componentDidMount(){
    try{
    document.querySelector("#root > div > div > section.accountPage > section > section > div > div:nth-child(2) > form > button").style.marginTop="12px"
    document.querySelector("#root > div > div > section.accountPage > section > section > div > div:nth-child(2) > form > button").style.marginBottom="-12px"}
    catch{}
    window.gtag('event','page_view',{'page_location':'Plus Card Form'})
    sendAnalytics("pluspayform","Free user reached the plus pay form.",String(new Date()))
  }

  handlePayBtnShow(){
    if(this.state.transactionState==="in-process"){
      return(<div style={{paddingTop:10,fontSize:14,fontWeight:700}}>Processing...please wait.</div>)
    }
    else{
      return(
        <button type="submit" disabled={!this.props.stripe} className="pay-with-stripe-btn">
            Pago Seguro Con Stripe
        </button>
      )
    }
  }

  render() {
    return (
      <form className='payForm' onSubmit={this.handleSubmit}>
          <div className='breakDownBox' style={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
            <div><img alt="" style={{width:80,height:80}} src={logo}/></div>
            <div>
              <div>Suscription...........${this.state.suscriptionPrice}</div>
              <div>Tax......................${(this.state.suscriptionPrice*0.1).toFixed(2)}</div>
              <div>Total..................${(this.state.suscriptionPrice+(this.state.suscriptionPrice*0.1)+0.03).toFixed(2)}</div>
            </div>
          </div>
          <CardElement options={CARD_ELEMENT_OPTIONS}/>
          {this.handlePayBtnShow()}
          <div className='errorMessageCard'>{this.state.transactionMessage}</div>
          <Paypalbutton/>
          <div style={{color:'rgba(0,0,0,.5)'}}>Podra cancelar su membresia en cualquier momento.</div>
        </form>
    );
  }
}

const InjectedCheckoutForm = () => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
);

export default InjectedCheckoutForm;
