import React,{Component} from 'react';
import './Prompt.css';
import Logo from '../../images/CASADATA.png';
import sendAnalytics from '../../Functions/analytics';
import serverIp from '../../Functions/serverConfig';
class Prompt extends Component{
    constructor(props){
        super(props);
        this.state={
            
        }
    }
    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    endTutorialPrompt1(){
        let url=`${serverIp}/api/endtutorialprompt`;
        let token = '';
        if(!localStorage.getItem('token')){
          token = sessionStorage.getItem('token')
        }
        else{
          token = localStorage.getItem('token')
        }
        fetch(url,{
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control':'no-cache',
            'Referrer':'https://casadata.io/',
            // Include the token in the Authorization header
            'Authorization': `Bearer ${token}`,
          },
          redirect: "follow", // manual, *follow, error
          // referrerPolicy:"no-referrer-when-downgrade",
        })
        .then(()=>this.props.closePrompt())
      }

    endTutorialPrompt2(){
    let url=`${serverIp}/api/endtutorialprompt`;
    let token = '';
    if(!localStorage.getItem('token')){
        token = sessionStorage.getItem('token')
    }
    else{
        token = localStorage.getItem('token')
    }
    fetch(url,{
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
        'Content-Type': 'application/json',
        'Cache-Control':'no-cache',
        'Referrer':'https://casadata.io/',
        // Include the token in the Authorization header
        'Authorization': `Bearer ${token}`,
        },
        redirect: "follow", // manual, *follow, error
        // referrerPolicy:"no-referrer-when-downgrade",
    })
    .then(()=>this.props.goToTutotial())
    }

    promptIndexer(){
        if(this.props.trials>1){
            return(
                this.languageIndexer(
                    "¡Aprende a aprovechar al máximo nuestra aplicación con nuestro tutorial de solo 5 minutos y obtén 2 búsquedas PluxMAX gratis!",
                    'Learn how to get the most out of our app with our tutorial and receive 2 PluxMAX searches free!'
                    )
            );
        }
        else{
            return(
                this.languageIndexer(
                    "¡Aprende a aprovechar al máximo nuestra aplicación con nuestro tutorial de solo 5 minutos!",
                    'Learn how to get the most out of our app with our tutorial!'
                    )
            );
        }
    }

    render(){
        return(
            <section
            onMouseDown={()=>this.props.holdTheMapStill()}
            onTouchStart={()=>this.props.holdTheMapStill()}
            onMouseUp={()=>this.props.releaseTheMap()}
            onTouchEnd={()=>this.props.releaseTheMap()}
            className='promptContainer'>
                <div className='promptInner'>
                    <div className='logoContainer'><img alt="" src={Logo} height='60px' width='65px'></img></div>
                    <div className='welcomeMessage'>
                        {this.languageIndexer("BIENVENIDOS A CASADATA!",'WELCOME TO CASADATA!')}
                    </div>
                    <div className='questionText'>
                        {this.promptIndexer()}
                        <div style={{color:'rgba(0,0,0,.6)',fontSize:12}}>*Solo Disponible Para Usuarios Nuevos*</div>
                    </div>
                    <div className='askButtons'>
                        <button onClick={()=>{
                            this.endTutorialPrompt2()
                            //PAGE,ACTION,DATE
                            sendAnalytics("TUTORIAL PROMPT","USER ACCEPTED TUTORIAL",String(new Date()))
                            }}>{this.languageIndexer("Ver Tutorial",'Watch Turorial')}</button>
                        <button style={{background:'rgba(0,0,0,.35)'}}onClick={()=>{
                            this.endTutorialPrompt1()
                            //PAGE,ACTION,DATE
                            sendAnalytics("TUTORIAL PROMPT","USER DECLINED TUTORIAL",String(new Date()))
                            }}>{this.languageIndexer("No, Gracias!",'No, thanks!')}</button>
                    </div>
                </div>
            </section>
        );
    }
}

export default Prompt;