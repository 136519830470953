import React, { Component } from 'react';
import './UpgradePage.css';
import PlusCardForm from '../CardForm/PlusCardForm';
import CheckoutForm from '../CardForm/PlusMaxCardForm';
import CheckoutFormEnglish from '../CardForm/PlusMaxCardFormEnglish';
import ImageGallery from "react-image-gallery";
import plusMaxImg0 from '../../images/plusMaxDemonstration0.jpg'
import plusMaxImg1 from '../../images/plusMaxDemonstration1.jpg'
import plusMaxImg2 from '../../images/plusMaxDemonstration2.png'
import plusMaxImg3 from '../../images/plusMaxDemonstration3.png'
import plusImg0 from '../../images/plusDesmonstration0.jpg';
import plusImg1 from '../../images/plusDesmonstration1.png';
import plusImg2 from '../../images/plusDesmonstration2.png';
import sendAnalytics from '../../Functions/analytics';
import "./image-gallery.css";

const images = [
    {original:plusMaxImg0},
    {original:plusMaxImg1},
    {original:plusMaxImg2},
    {original:plusMaxImg3}
]

const images2 = [
    {original:plusImg0},
    {original:plusImg1},
    {original:plusImg2}
]

class UpgradePage extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedLanguage:this.props.selectedLanguage
        }
    }

    fixUpgradeWindowHeight(){
        try{
            if(window.innerWidth<872){
                document.querySelector('#root > div > div > section.accountPage').scrollTo({
                    top: 450,
                    left: 0,
                    behavior: 'smooth'
                  });
            }
            else{
                document.querySelector('#root > div > div > section.accountPage').scrollTo({
                    top: 320,
                    left: 0,
                    behavior: 'smooth'
                  });
            }
        }catch{}
    }

    // componentDidUpdate(){
    //     this.fixUpgradeWindowHeight()
    // }

    componentDidMount(){
        this.fixUpgradeWindowHeight()
        // window.addEventListener('resize',()=>this.fixUpgradeWindowHeight())
        // window.addEventListener('scroll',()=>this.fixUpgradeWindowHeight())
    }

    checkoutFormIndexer(){
        if(this.props.selectedLanguage==='spanish'){
            return <CheckoutForm/>
        }
        else{
            return <CheckoutFormEnglish/>
        }
    }

    upgradeRenderIndex(){
        if(this.props.upgradeLevel==='Plus'){
            return(
                <section style={{
                    height:document.querySelector('#root > div > div > section.accountPage').scrollHeight
                    }} className='upgradePage'>
                    <div className='upgradePageInner' style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center'
                        }}>
                        <div style={{
                            display:'flex',
                            justifyContent:'flex-end',
                            marginTop:10,
                            marginRight:10,
                            width:'100%'
                            }}>
                            <button
                            onLoadStart={()=>this.fixUpgradeWindowHeight()}
                            onClick={()=>{
                                this.props.closeUpgradeWindow()
                            }}
                            className='closeBtnPay'>Close</button></div>
                        <div className='upgradeHeader'>
                            Adquiere las herramientas esenciales para tomar una decision informada.
                        </div>
                        <div className='plusMaxBenefitsShowcase' style={{marginBottom:20}}>
                            <ImageGallery 
                            items={images2} />
                            <ul className='benefitList' style={{listStyle:'none'}}>
                                <li className='plusBullet'>Filtra por precio.</li>
                                <li className='plusBullet'>Filtra por tama&ntilde;o de propiedad.</li>
                                <li className='plusBullet'>Filtra por fecha de transacci&oacute;n.</li>
                                <li className='plusBullet'>Estimado de valor intrinsico.</li>
                                <li className='plusBullet'>Estimado de equidad.</li>
                                <li className='plusBullet'>Herramientas de an&aacute;lisis estad&iacute;stico.</li>
                                <li className='plusBullet'>Guarda / Baja datos en formato CSV compatible con EXCEL.</li>
                            </ul>
                        </div>
                        
                        <PlusCardForm/>
                    </div>
                </section>
                );
            }
        else if(this.props.upgradeLevel==='PlusMAX'){
            return(
                <section style={{
                    height:document.querySelector('#root > div > div > section.accountPage').scrollHeight
                    }} className='upgradePage'>
                    <div on className='upgradePageInner upGradePlusMaxInner'>
                        <div style={{
                            display:'flex',
                            justifyContent:'flex-end',
                            marginTop:10
                            // marginRight:10
                            }}>
                            <button
                            onClick={()=>{
                                this.props.closeUpgradeWindow()
                                sendAnalytics("UPGRADEPAGE","Pagina Upgrade Plusmax Cerrada",String(new Date()))
                            }}
                            className='closeBtnPay'>{this.languageIndexer("CERRAR",'CLOSE')}</button></div>
                        <div style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center'}}>
                            <div className='upgradeHeader'>
                            {this.languageIndexer("Todas nuestras herramientas sin limites ni restricciones.",
                            'All our tools without limits or restrictions')}
                            </div>
                            <div className='plusMaxBenefitsShowcase'>
                                <ImageGallery 
                                items={images} />
                                <ul className='benefitList' style={{listStyle:'none'}}>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Precios de venta",'Sales Prices')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Fechas de Venta",'Sales Dates')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Tamaño de Estructura",'Structure Sizes')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Tamaño de Terreno",'Land Sizes')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Busquedas ilimitadas",'Unlimited Searches')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Filtra por tamaño de estructura",'Filter by Structure Size')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Filtra por fecha de transacción",'Filter by Dates')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Filtra por precios",'Filter by Prices')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Estimado de valor de propiedad",'Property Value Estimate')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Herramientas de análisis estadístico",'Statistical Analysis Tools')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Bajar / Exportar Datos",'Download / Export Data')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Ver comprador / vendedor.",'Access to Buyer / Seller')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Marcador de reposeidas.",'Repossesed Marker')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Indicador de inundables.",'Floodzone Indicator')}
                                    </strong></li>
                                    <li className='plusMaxBullet'><strong>
                                        {this.languageIndexer("Estimado de Mensualidad CRIM",'Monthly Property Tax Estimate')}
                                    </strong></li>
                                </ul>
                            </div>  
                            {this.checkoutFormIndexer()}
                        </div>
                    </div>
                </section>
                );
            }
        }
    
    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
        }

    render(){
        return(
            <section>{this.upgradeRenderIndex()}</section>
        );
    }
}

export default UpgradePage;