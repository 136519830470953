import React,{Component} from 'react';
import './phoneChange.css';
import serverIp from '../../../Functions/serverConfig';


class PhoneChange extends Component{

    constructor(props){
        super(props);
        this.state={
            signUpPhoneNumber:'',
            phoneNoStatus:''
        }
    }

    handlePhoneNumber(data){
        const regex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
        if(regex.test(data)){
            this.setState({signUpPhoneNumber:[data,'valid']})
        }
        else if(data===''){
            this.setState({signUpPhoneNumber:['','invalid']})
        }
        else{
            this.setState({signUpPhoneNumber:['','invalid']})
        }
    }

    sendPhoneNumber(){
        if(this.state.signUpPhoneNumber[1]==='valid'){
            this.setState({phoneNoStatus:''})
            let token = localStorage.getItem('token');
            // Check if the token is not found
            if (!token) {
                token = sessionStorage.getItem('token');
            }
            let url = `${serverIp}/api/phoneChange`;
            fetch(url,{
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                  'Content-Type': 'application/json',
                  // Include the token in the Authorization header
                  'Authorization': `Bearer ${token}`,
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer",
                // referrer:"https://catastro.crimpr.net/cdprpc/",
                // referrerPolicy:"no-referrer-when-downgrade",
                body: JSON.stringify({'newPhoneNumber':this.state.signUpPhoneNumber}), // body data type must match "Content-Type"
              })
              .then(res => {
                if (!res.ok) {
                  throw new Error('Network response was not ok');
                }
                return res.json();
              })
              .then(data => {
                if(localStorage.getItem('token')!=null){
                  localStorage.setItem('token',data.newToken)
                }
                else if(sessionStorage.getItem('token')!=null){
                  sessionStorage.setItem('token',data.newToken)
                }
                window.location.reload()
              })
              .catch(error => {
                this.setState({phoneNoStatus:error.message});
              })
        }
        else{
            this.setState({phoneNoStatus:'Numero telefonico invalido.'})
        }
    }

    languageIndexer(spanishMessage,englishMessage){
      if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
      else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    render(){
        return(
        <section className='phoneChangeArea'>
             <div>{this.languageIndexer("¡En CasaData, su tranquilidad es nuestra prioridad! Siempre estamos a su disposición para ofrecerle la asistencia que merece. ¿Tiene alguna pregunta, sugerencia o inquietud? Por favor, no dude en hacernos llegar sus comentarios contactando con nosotros al correo electrónico","At CasaData, your peace of mind is our priority! We are always here to provide you with the assistance you deserve. Do you have any questions, suggestions, or concerns? Please don't hesitate to share your feedback by contacting us via email.")} <strong>information@casadata.io</strong><br></br> {this.languageIndexer("Nuestro compromiso es responderle a la mayor brevedad posible, porque su satisfacción es el núcleo de nuestro servicio. ¡Estamos aquí para ayudarle!",'Our commitment is to respond as quickly as possible because your satisfaction is at the heart of our service. We are here to help!')}</div>
            <div style={{display:'flex',justifyContent:'center'}}>
                <button className='returnBtn' onClick={()=>this.props.closePhoneChange()} style={{margin:5,width:'50%',cursor:'pointer'}}>{this.languageIndexer("ATRAS",'CLOSE')}</button>
            </div>
        </section>
        );
    }
}

export default PhoneChange;