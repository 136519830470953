//////////////////////////////////////////////////PAYPAL//////////////////////////////////////////////////
//Paypal Plus Variables

// const paypalPlusPlanId = "P-61007003PP674441GMWAQILA";//TEST MODE
const paypalPlusPlanId = "P-8CE76735WG365773BMYRMKCI";//COMMERCIAL MODE

//Paypal PlusMAX Variables

// const paypalPlusMaxPlanId = "P-0CX347935S540730NMWASMOQ";//TEST MODE
const paypalPlusMaxPlanId = "P-93R30758CE113644SMYRMIXY";//COMMERCIAL MODE

//Paypal Client ID

// const paypalClientId = "AZFEIWoqP0847Cie8NKwDoVihhlFg1lSXiQg3FjKm5frGzk9zMqe3IDZpg7ShDQw3HJuaL4xWYIUQ03D";//TEST MODE
const paypalClientId = "AeGW4yYbsEVbZNA8_9t3P_7AsL5RfE5rtGGrgOKWNvChFsQ7eBOhcEkp61nln4nMW8Uy8ZBEfPldDBnj";//COMMERCIAL MODE

//////////////////////////////////////////////////STRIPE//////////////////////////////////////////////////
//Stripe publishable key

// const stripePublishableKey = 'pk_test_51O7KqoDxPYx0d3rrGcfARzvg3x1f5cCKYbXMTfqjLgEw0zMJBEAp2tSN2IXx1kWr1hLB63MO111lC5lUOuufO05w00YRUpHAkY';//TEST MODE
const stripePublishableKey = 'pk_live_51O7KqoDxPYx0d3rrSfKvB1eUO4QpHabVhh5rp4dr5bk408r9KMtgVmxL6PLW7GaGyM8NmPSohFSz2FmVibD0PARE00Xf6sLCR8';//COMMERCIAL MODE

//Stripe Plus Subscription Plan ID---------------------------------------------------------------

// const stripePlusSubscriptionPlanID = 'price_1OdnpiDxPYx0d3rr0gyvc8ln';//TEST MODE
// const stripePlusSubscriptionPlanID = 'price_1P7MsgDxPYx0d3rrLJ15RdvA';//COMMERCIAL MODE

//Stripe PlusMax Subscription Plan ID------------------------------------------------------------

// const stripePlusMaxSubscriptionPlanID = 'price_1OdnlPDxPYx0d3rrpC7QBnfu';//TEST MODE
// const stripePlusMaxSubscriptionPlanID = 'price_1P7MuVDxPYx0d3rrkWy3a6re';//COMMERCIAL MODE

// Stripe monthly plusmax subscription plan.
// const stripePlusMaxSubscriptionPlanIDMonthly = 'price_1PM2M5DxPYx0d3rrRb0QfC5u';//TEST MODE
const stripePlusMaxSubscriptionPlanIDMonthly = 'price_1PXWMrDxPYx0d3rrEVUw6ssj';//COMMERCIAL MODE

// Stripe yearly plusmax subscription plan.
// const stripePlusMaxSubscriptionPlanIDYearly = 'price_1PM2OFDxPYx0d3rr8pBcIeJR';//TEST MODE
const stripePlusMaxSubscriptionPlanIDYearly = 'price_1PXWMlDxPYx0d3rrXqq98kFi';//COMMERCIAL MODE

// Paypal monthly plusmax subscription plan.
const paypalPlusMaxPlanIdMonthly = 'P-1MA289689W667282BM2AN2PQ'; //COMMERCIAL MODE

// Paypal yearly plusmax subscription plan.
const paypalPlusMaxPlanIdYearly = 'P-8P206718PB394524CM2AN7CQ'; //COMMERCIAL MODE

//////////////////////////////////////////////////PRICING////////////////////////////////////////////////
//Plus Plan Monthly Cost

const plusPrice = 5

//Plus MAX Plan Monthly Cost

const plusMaxPrice = 8

////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

//NEW PRICE POINTS/////////////////////////////////////////////////////////////////////////////////////

const plusMaxDaily = 4

const plusMaxWeekly = 7

const plusMaxMonthly = 12

const plusMaxYearly = 75

//TEST SUBCRIPTION ID'S FOR NEW PRICE POINTS///////////////////////////////////////////////////////////

const plusMaxBiDailySubscriptionID = "price_1PM2J0DxPYx0d3rruRFZqNd6";

const plusMaxWeeklySubscriptionID = "price_1PM2KNDxPYx0d3rrdUcZ97BU";

const plusMaxMonthlySubscriptionID = "price_1PM2M5DxPYx0d3rrRb0QfC5u";

const plusMaxYearlySubscriptionID = "price_1PM2OFDxPYx0d3rr8pBcIeJR";

//Payment configuration object
const paymentConfig = {
    paypalPlusPlanId,
    paypalClientId,
    paypalPlusMaxPlanId,
    stripePublishableKey,
    // stripePlusSubscriptionPlanID,
    // stripePlusMaxSubscriptionPlanID,
    plusPrice,
    plusMaxPrice,
    plusMaxDaily,
    plusMaxWeekly,
    plusMaxMonthly,
    plusMaxYearly,
    plusMaxBiDailySubscriptionID,
    plusMaxWeeklySubscriptionID,
    plusMaxMonthlySubscriptionID,
    plusMaxYearlySubscriptionID,
    stripePlusMaxSubscriptionPlanIDMonthly,
    stripePlusMaxSubscriptionPlanIDYearly,
    paypalPlusMaxPlanIdMonthly,
    paypalPlusMaxPlanIdYearly
}

export default paymentConfig;