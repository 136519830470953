import React, { Component } from 'react';
import Countdown from 'react-countdown';
import './Promo.css';

class Promo extends Component {
  
  // constructor(props){
  //   super(props);
  // }
//   componentDidMount(){
//     console.log(this.props.coolDownStart)
//     console.log(new Date(new Date(this.props.coolDownStart).getTime()+(4 * 60 * 60 * 1000)))
//   }

  componentDidMount(){
    window.gtag('event','page_view',{'page_location':'Promo Limit'})
  }


  closeUpgradeWindow(){
    this.setState({upGradeShow:false})
  }

  languageIndexer(spanishMessage,englishMessage){
    if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
    else if(this.props.selectedLanguage==='english'){return(englishMessage);}
}
 
  render() {
    // You can conditionally render content based on whether the token is expired
    return (
        <div 
        className='promoContainer'
        onMouseDown={this.props.holdTheMapStill}
        onTouchStart={this.props.holdTheMapStill}
        onMouseUp={this.props.releaseTheMap}
        onTouchEnd={this.props.releaseTheMap}
        >
        <div className='countdown'>
            <div className='countDownText'>
            {this.languageIndexer(
            "¡Alcanzaste el límite de búsquedas que ofrece tu membresía básica! Evita la espera  y eleva tu análisis de mercado con un upgrade a una membresía Plus o PlusMAX. ¡Accede a herramientas avanzadas y optimiza tu estrategia ahora!",
            'You’ve reached the search limit offered by your basic membership! Avoid the wait and elevate your market analysis with an upgrade to a Plus or PlusMAX membership. Access advanced tools and optimize your strategy now!')
            }
            </div>
            <div className='busquedas'>{this.languageIndexer("Mas Búsquedas disponibles en",'More searches available in')}...</div>
            <Countdown date={new Date(new Date(this.props.coolDownStart).getTime()+(4 * 60 * 60 * 1000)).getTime()+(24 * 60 * 60 * 1000)} />
        </div>
        <div className="flip-cards-container2">
            <div className="flip-card2">
            <div className="flip-card-inner2">
                <div className="flip-card-front2 cardMaxFront2">
                    <div className='maxBanner2'>Plus MAX</div>
                </div>
                <div id="maxcardback" className="flip-card-back cardMaxBack">
                    <div>{this.languageIndexer("Filtra por precio",'Filter by price')}.</div>
                    <div>{this.languageIndexer("Filtra por tamaño de propiedad",'Filter by property size')}</div>
                    <div>{this.languageIndexer("Filtra por fechas",'Filter by dates')}</div>
                    <div>{this.languageIndexer("Estiamado de valor",'Value Estimates')}</div>
                    <div>{this.languageIndexer("Estiamado de Equidad",'Equity Estimate')}</div>
                    <div>{this.languageIndexer("Herramientas de Analisis Estadistico",'Equity Estimate')}</div>
                    <div>{this.languageIndexer("Guardar / Bajar Datos",'Download/Export Data')}</div>
                    <div style={{background:'#b71540'}}>{this.languageIndexer("Ver Propietario",'See Property Owner')}</div>
                    <div style={{background:'#b71540'}}>{this.languageIndexer("Ver Vendedor",'See Property Seller')}</div>
                    <div style={{background:'#b71540'}}>{this.languageIndexer("Marcador de Reposeidas",'Reposessed Markers')}</div>
                    <div style={{background:'#b71540'}}>{this.languageIndexer("Marcador de Re-Financeadas",'Refinanced Marker')}</div>
                    <div style={{background:'#b71540'}}>{this.languageIndexer("Indicador de Inundables",'FloodZone Indicator')}</div>
                    <button onClick={()=>this.props.upgradeWindowMax()}>UPGRADE</button>
                </div>
            </div>
        </div>
        </div>
    <div onClick={()=>this.props.closePromoWindow()} className='closeBtnPromo'>{this.languageIndexer("Volver a Mapa",'Back To Map')}</div>
    </div>
    );
  }
}

export default Promo;
