import React,{Component} from 'react';
import './LoginPage.css';
import sendAnalytics from '../../Functions/analytics';
import { GoogleLogin } from '@react-oauth/google';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

class LoginPage extends Component{

    constructor(props){
        super(props);
        this.state={
            username:'',
            password:'',
            serverIpAddress:this.props.serverIp,
            serverResponse:'',
            persist:false,
            userConfirmation:"userConfirmed",
            validationCode:'',
            loginShowOrHide:'show',
            fingerprint:''
        }
    }

    componentDidMount(){
        FingerprintJS.load().then(fp => fp.get()).then(result=>this.setState({fingerprint:result.visitorId}))
    }
  

    sendLoginData(){
        if(this.state.username!==''&&this.state.password!==''){
        this.setState({serverResponse:''})
        fetch(`${this.state.serverIpAddress}/api/login`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                email:this.state.username,
                password:this.state.password,
                validationCode:this.state.validationCode,
                fingerprint:this.state.fingerprint
            }) 
          })
          .then(response => {
            return response.json()
            })
          .then(response=>{
            if(
                response.ServerResponse==='Incorrect username or password'||
                response.ServerResponse==='An unexpected error occurred on the server.'
                ){
                this.setState({serverResponse:response.ServerResponse,loginShowOrHide:'show'})
            }
            else if(response.ServerResponse==='User unconfirmed'&&this.props.selectedLanguage==='spanish'){
                this.setState({
                    userConfirmation:"userUnconfirmed",
                    serverResponse:'Favor entrar codigo de confirmacion valido.',
                    loginShowOrHide:'show'
                })
            }
            else if(response.ServerResponse==='User unconfirmed'&&this.props.selectedLanguage==='english'){
                this.setState({
                    userConfirmation:"userUnconfirmed",
                    serverResponse:'Please enter a valid confirmation code.',
                    loginShowOrHide:'show'
                })
            }
            else{
                if(this.state.persist===true){
                    this.setState({serverResponse:""})
                    localStorage.setItem('token', response.ServerResponse);
                    if(this.state.validationCode!==''){
                        //PAGE,ACTION,DATE
                        window.fbq('track', 'CompleteRegistration'); 
                        sendAnalytics("LOGIN","User validated and logged in.",String(new Date()))
                        this.props.userFirstLogin()
                    }
                    this.props.userIsLoggedIn()
            }
                else{
                    this.setState({serverResponse:""})
                    if(this.state.validationCode!==''){
                        //PAGE,ACTION,DATE
                        sendAnalytics("LOGIN","User validated and logged in.",String(new Date()))
                        this.props.userFirstLogin()
                    }
                    sessionStorage.setItem('token', response.ServerResponse);
                    this.props.userIsLoggedIn() 
                }
            }
          })
          .catch(()=>this.setState({loginShowOrHide:'show'}))
        }
        else{
            this.setState({serverResponse:'Favor de entrar email y password valido.',loginShowOrHide:'show'})
        }
    }

    getNewValidationCode(){
        if(this.state.username!==''){
        this.setState({loginShowOrHide:'hide'})
        fetch(`${this.state.serverIpAddress}/api/newCode`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                email:this.state.username,
            }) 
          })
          .then(res=>res.json())
          .then(data=>{
            this.setState({loginShowOrHide:'show',serverResponse:data.ServerResponse})
          })
          .catch(()=>this.setState({loginShowOrHide:'show'}))
        }
    }

    loginShowOrHide(){
        if(this.state.loginShowOrHide==='show'){
            return(<button onClick={()=>{
                this.setState({loginShowOrHide:'hide'})
                this.sendLoginData()
            }} style={{cursor:"pointer"}}>Login</button>);
        }
        else{
            return(<div>Procesando porfavor espere un segundo...</div>);
        }
    }
    
    sendNewCodeShowOrHide(){
        if(this.state.loginShowOrHide==='show'){
            return(<button onClick={()=>this.getNewValidationCode()} style={{cursor:"pointer"}}>{this.languageIndexer("Codigo nuevo",'New Code')}</button>);
        }
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }
    

    renderConfirmationIndex(){
        if(this.state.userConfirmation==='userConfirmed'){
            return(
                <section className='loginPage'>
                    <div className='pageCloseContainer'>
                        <div onClick={()=>this.props.closeLoginWindow()} className='pageCloseBtn'>
                            <div className='xStick1'></div>
                            <div className='xStick2'></div>
                        </div>
                    </div>
                    <div className='loginContainerOuter'>
                        <div className='loginContainer'>
                        <div>
                            <div style={{color:'rgba(255,255,255,.7)'}}>
                                {this.languageIndexer("Entrar con Gmail!",'Login with Gmail!')}
                            </div>
                            <GoogleLogin
                                onSuccess={(res)=>this.onSuccess(res)}
                                onError={(res)=>this.onFailure(res)}
                                cookiePolicy={ 'single_host_origin' }
                                responseType='code,token'
                            />
                            <div style={{
                                height:2,
                                width:'100%',
                                background:'rgba(255,255,255,.7)',
                                marginTop:15
                            }}></div>
                            <div style={{paddingTop:10,color:'rgba(255,255,255,.7)'}}>
                                {this.languageIndexer("Entrar con otro email!",'Login with other email!')}
                            </div>
                            </div>
                            <div>
                                <div>Email</div>
                                <div><input onChange={(data)=>{this.setState({username:data.target.value})}} type='text'></input></div>
                            </div>
                            <div>
                                <div>Password</div>
                                <div><input onChange={(data)=>{this.setState({password:data.target.value})}} type='password'></input></div>
                            </div>
                            {this.loginShowOrHide()}
                            <button style={{width:150}} onClick={()=>this.props.switchToForgotPw()} className='forgotPasswordLink'>
                                {this.languageIndexer("Olvidé mi contraseña",'Forgot password?')}
                            </button>
                            <div style={{marginBottom:-12,marginTop:15,color:'red'}} className='serverLoginResponse'>{this.state.serverResponse}</div>
                            <div className='persistentContainer'>
                                <div className='persistenText'>
                                    {this.languageIndexer("Mantenme Logeado",'Keep me logged in')}
                                </div>
                                <div className='persistenInput'><input onClick={(data)=>this.setState({persist:data.target.checked})} type='checkbox'></input></div>
                            </div>
                        </div>
                    </div>  
                </section>
            );    
        }
        else{
            return(
                <section className='loginPage'>
                    <div className='pageCloseContainer'>
                        <div onClick={()=>this.props.closeLoginWindow()} className='pageCloseBtn'>
                            <div className='xStick1'></div>
                            <div className='xStick2'></div>
                        </div>
                    </div>
                    <div className='loginContainerOuter'>
                        <div className='loginContainerUnconfirmed'>
                            <div>
                                <div>Email</div>
                                <div><input value={this.state.username} onChange={(data)=>{this.setState({username:data.target.value})}} type='text'></input></div>
                            </div>
                            <div>
                                <div>Password</div>
                                <div><input onChange={(data)=>{this.setState({password:data.target.value})}} type='password'></input></div>
                            </div>
                            <div>
                                <div>
                                    {this.languageIndexer("Codigo de Validacion",'Validation Code')}
                                </div>
                                <div> <input
                                            onChange={(data) => this.setState({ validationCode: data.target.value })}
                                            value={this.state.validationCode} // Bind the value to the state
                                            type='text'
                                        /></div>
                            </div>
                            <div style={{display:'flex',alignItems:'center',width:300,justifyContent:'space-around'}}>
                                 {this.loginShowOrHide()}
                                 {this.sendNewCodeShowOrHide()}
                            </div>
                            <div style={{marginTop:0,marginBottom:0,color:'red'}} className='serverLoginResponse'>{this.state.serverResponse}</div>
                            <button style={{width:200}} onClick={()=>this.props.switchToForgotPw()} className='forgotPasswordLink'>
                                {this.languageIndexer("Olvidé mi contraseña",'Forgot password?')}
                            </button>
                            <div className='persistentContainer'>
                                <div className='persistenText'>{this.languageIndexer("Mantenme Logeado",'Keep me logged in')}</div>
                                <div className='persistenInput'><input onClick={(data)=>this.setState({persist:data.target.checked})} type='checkbox'></input></div>
                            </div>
                        </div>
                    </div>  
                </section>
            );
        }
    }

    onSuccess(response){
        fetch(`${this.state.serverIpAddress}/api/auth/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: response.credential, fingerprint:this.state.fingerprint }),
            })
            .then(response => response.json())
            .then(data => {
                sessionStorage.setItem('token', data.response);
                this.props.userIsLoggedIn() 
            })
            .catch((error) => {
            console.error('Error:', error);
            });
      };
    
    onFailure(response){
        console.error('Login Failed:', response);
      };

    render(){
        return(
            this.renderConfirmationIndex()
        );
    }
}

export default LoginPage;