import React, { Component } from 'react';
import './AccountPage.css';
import jwt_decode from 'jwt-decode';
import UpgradePage from '../UpgradePage/UpgradePage';
import PhoneChange from './Subwindows/PhoneChange';
import DeleteAcct from './Subwindows/deleteAcct';
import ChangePW from './Subwindows/changePw';
import HandleAccountStatus from './Subwindows/handleAccountStatus';
// import sendAnalytics from '../../Functions/analytics';
class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decodedToken: null,
      upGradeShow:false,
      upgradeLevel:'',
      buttonShowIndex:'',
      plusMaxPrice:['8',' 2 días'],
      selectedPayment1:true,
      selectedPayment2:false,
      selectedPayment3:false,
      selectedPayment4:false
    };
  }

  componentDidMount() {
    // Retrieve the token from localStorage
    
    if(this.props.selectedPayment==='daily'){
      this.setState({selectedPayment1:true,selectedPayment2:false,selectedPayment3:false,selectedPayment4:false,plusMaxPrice:['8',' 2 días','daily'],indexedSavings:''})
    }
    else if(this.props.selectedPayment==='weekly'){
      this.setState({selectedPayment1:false,selectedPayment2:true,selectedPayment3:false,selectedPayment4:false,plusMaxPrice:['20',' 7 días','weekly'],indexedSavings:'AHORRE 30% = $2.85/dia'})
    }
    else if(this.props.selectedPayment==='monthly'){
      this.setState({selectedPayment1:false,selectedPayment2:false,selectedPayment3:true,selectedPayment4:false,plusMaxPrice:['30',' Mensual','monthly'],indexedSavings:'AHORRE 75% = $1.00/dia'})
    }
    else if(this.props.selectedPayment==='annual'){
      this.setState({selectedPayment1:false,selectedPayment2:false,selectedPayment3:false,selectedPayment4:true,plusMaxPrice:['288',' Anual','annual'],indexedSavings:'AHORRE 80% = $0.79/dia'})
    }

    let token;

    token = localStorage.getItem('token');
    
    if(token===null){
      token = sessionStorage.getItem('token');
    }

    if (token) {
      // Decode the token
      const decodedToken = jwt_decode(token);

      // Check if the token has expired

      // Update state with the token and its expiration status
      this.setState({ decodedToken });
    }
    this.autoPickUpgrade()
    window.gtag('event','page_view',{'page_location':'Account Page'})
  }

  languageIndexer(spanishMessage,englishMessage){
    if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
    else if(this.props.selectedLanguage==='english'){return(englishMessage);}
  }

  phoneChangeIndexer(){
    if(this.state.buttonShowIndex==='phoneNo'){
      return(<PhoneChange selectedLanguage={this.props.selectedLanguage} closePhoneChange={()=>this.setState({buttonShowIndex:''})}/>);
    }
    else if(this.state.buttonShowIndex==='deleteAcct'){
      return(<DeleteAcct selectedLanguage={this.props.selectedLanguage} closeDeleteAcct={()=>this.setState({buttonShowIndex:''})}/>);
    }
    else if(this.state.buttonShowIndex==='changePW'){
      return(<ChangePW selectedLanguage={this.props.selectedLanguage} closeDeleteAcct={()=>this.setState({buttonShowIndex:''})}/>);
    }
    else if(this.state.buttonShowIndex==='handleMembership'){
      return(this.handleAccountStatusShow());
    }
    else{
      return(
        <div className='accountBtns'>
          <div class="button-enhanced" onClick={()=>this.setState({buttonShowIndex:'phoneNo'})}>
            {this.languageIndexer("Contactanos",'Contact Us')}
          </div>
          <div class="button-enhanced" onClick={()=>this.setState({buttonShowIndex:'deleteAcct'})}>
            {this.languageIndexer("Borrar Cuenta",'Delete Account')}
          </div>
          <div class="button-enhanced" onClick={()=>this.setState({buttonShowIndex:'changePW'})}>
            {this.languageIndexer("Cambiar Contraseña",'Change Password')}
          </div>
          {this.showIfSubscriptionIsActive()}
        </div>
      );
    }
  }

  showIfSubscriptionIsActive(){
    if(this.state.decodedToken!==null&&(this.state.decodedToken.delta==="11A5D7A0C"||this.state.decodedToken.delta==="497CC0000")){
      return(<div class="button-enhanced" onClick={()=>this.setState({buttonShowIndex:'handleMembership'})}>
        {this.languageIndexer("Configuración de Subscripcion",'Subscription Configuration')}
      </div>)
    }
  }

  handleAccountStatusShow(){
    if(this.state.decodedToken.delta!==null){
      return(<HandleAccountStatus selectedLanguage={this.props.selectedLanguage} gammaRegister={this.renderDelta()} closeDeleteAcct={()=>this.setState({buttonShowIndex:''})}/>)
    }
  }
  
  renderHeaderText(){
    if(this.state.decodedToken!==null && this.props.selectedLanguage==='spanish'){
        return(`Hola, ${this.state.decodedToken.firstName}!`)
    }
    if(this.state.decodedToken!==null && this.props.selectedLanguage==='english'){
      return(`Hi, ${this.state.decodedToken.firstName}!`)
  }
  }

  renderDelta(){
    if(this.state.decodedToken!==null&&this.state.decodedToken.delta==="11A5D7A0C"){
        return("PlusMAX")
    }
    else if(this.state.decodedToken!==null&&this.state.decodedToken.delta==="497CC0000"){
        return("Plus")
    }
    else{
        return("Base")
    }
  }

  renderPhone(){
    if(this.state.decodedToken!==null){
      return(this.state.decodedToken.phone)
    }
  }

  upgradeWindowShow(upgradeLevel){
    this.setState({
      upGradeShow:true,
      upgradeLevel
  })
  }

  autoPickUpgrade(){
    if(this.props.subscriptionIndex!==""){
      this.setState({
        upGradeShow:true,
        upgradeLevel:this.props.subscriptionIndex
      })
    }
  }

  upgradeWindowRender(){
    if(this.state.upGradeShow===true){
      return(
        <UpgradePage
        selectedLanguage={this.props.selectedLanguage} 
        upgradeLevel={this.state.upgradeLevel}
        plusMaxIndexedPrice={parseInt(this.state.plusMaxPrice[0])}
        closeUpgradeWindow={
          ()=>this.closeUpgradeWindow()
        }
        serverIp={this.props.serverIp}
        />
      );
    }
  }


  flipCardShowCase(){
    if(!this.state.decodedToken || (this.state.decodedToken.delta!=="497CC0000" && this.state.decodedToken.delta!=="11A5D7A0C")){
      return(
        <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
          
          <div style={{background:'#e1b12c',height:540}} className='membershipCard membershipCardPlusMax'>
            <div className='membershipCardHeader'>
                <div style={{fontSize:20}}>PlusMAX</div>
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer("Precios de Venta",'Sale prices')}
            </div>
            <div className='membershipBullet plusMaxChanges'>
              {this.languageIndexer("Fechas de Ventas",'Sale Dates')}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer("Tamaños de estructuras",'Structure Sizes')}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer("Tamaños de terreno.",'Land Sizes')}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer("Busquedas ilimitadas!",'Unlimited Searches')}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer(
                  "Filtra por precio, tamaño y fecha de venta",
                  'Filter by sales price, size and sale dates'
                )}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer(
                  "Estimado de valor / equidad de propiedad",
                  'Property Value Estimate'
                )}
            </div>
            <div className='membershipBullet plusMaxChanges'>
              {this.languageIndexer(
                  "Herramientas de analisis estadistico",
                  'Statistical Analysis Tools'
                )}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer(
                  "Bajar / exportar datos.",
                  'Download / Export Data'
                )}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer(
                  "Ver comprador y vendedor",
                  'Access to names of buyer and seller'
                )}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer(
                  "Marcador de reposeidas y re-financeadas.",
                  'Repossessed Properties Marker'
                )}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer(
                  "Indicador de Inundables",
                  'Flood Zone Indicator'
                )}
            </div>
            <div className='membershipBullet plusMaxChanges'>
                {this.languageIndexer(
                  "Estimado Mensualidad CRIM",
                  'Monthly Property Tax Estimate'
                )}
            </div>
            <div 
            style={{marginTop:5,height:30,display:'flex',justifyContent:'center',alignItems:'center',fontWeight:700}}
            onClick={()=>{
                this.upgradeWindowShow('PlusMAX')
            }}
            className='joinBtn'>
              {this.languageIndexer(
                  "SELECCIONAR!",
                  'SELECT!'
                )}
            </div>
        </div>
      </div>
      );
    }
    else if(!this.state.decodedToken||this.state.decodedToken.delta==="497CC0000"){
      return(
        <div className="flip-card">
          <div style={{background:'#e1b12c',height:540}} className='membershipCard membershipCardPlusMax'>
            <div className='membershipCardHeader'>
                <div>PlusMAX</div>
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Precios de Venta
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Fechas de Venta
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Tamaños de estructuras.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Tamaños de terreno.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Busquedas ilimitadas!
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Filtra por precio, tamaño y fecha de venta.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Estimado de valor / equidad de propiedad.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Herramientas de analisis estadistico.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Bajar / exportar datos.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Ver comprador y vendedor.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Marcador de reposeidas y re-financeadas.
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Nombre de Vendedor y Comprador
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Indicador de Inundables
            </div>
            <div className='membershipBullet plusMaxChanges'>
                Estimado Mensualidad CRIM
            </div>
            <div 
            style={{marginTop:5}}
            onClick={()=>{
              this.upgradeWindowShow('PlusMAX')
            }}
            className='joinBtn'>SELECCIONAR!</div>
        </div>
      </div>
      );
    }
    else{
      return('')
    }
  }

  closeUpgradeWindow(){
    this.setState({upGradeShow:false})
    this.props.userRejectedUpgrade()
  }

  render() {
    // You can conditionally render content based on whether the token is expired
    return (
      <section
      onMouseDown={this.props.holdTheMapStill}
      onTouchStart={this.props.holdTheMapStill}
      onMouseUp={this.props.releaseTheMap}
      onTouchEnd={this.props.releaseTheMap}
      className='accountPage'>
        {this.upgradeWindowRender()}
        <div className='accountHeader'>
           <div>{this.renderHeaderText()}</div>
           <div onClick={()=>this.props.closeAccountWindow()} className='closeBtn'>
            {this.languageIndexer("CERRAR",'CLOSE')}
           </div>
        </div>
        <div className='memberDetails'>
            <div><strong style={{paddingRight:5}}>Member Plan:</strong>{this.renderDelta()}</div>
        </div>
        {this.phoneChangeIndexer()}
        <div> 
             {this.flipCardShowCase()}
        </div>
      </section>
    );
  }
}

export default AccountPage;
