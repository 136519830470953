import React, { Component } from 'react';
import './handleAccountStatus.css'
import serverIp from '../../../Functions/serverConfig';
class HandleAccountStatus extends Component{
    constructor(props){
        super(props);
        this.state={
            accountCancelationWindow:'closed',
            deltaRegister:'',
            suscriptionIsCanceled:'',
            suscriptionCheckError:'',
            sid:"",
            pid:""
        }
    }

    handleAccountCancelationWindowOpen(){
        if(this.state.accountCancelationWindow==="closed"){
            this.setState({accountCancelationWindow:"open"})
        }
        else{
            this.setState({accountCancelationWindow:"closed"})
        }
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
      }

    deleteAccountSubscription(){
        try{document.querySelector("#root > div > div > section.accountPage > section > div.accountCancelationPage > button").style="display:None;"}
        catch{}
        try{document.querySelector("#root > div > div > section.accountPage > section > div.accountCancelationPage > button").style="display:None;"}
        catch{}
        let token = localStorage.getItem('token');
        // Check if the token is not found
        if (!token) {token = sessionStorage.getItem('token');}
        ////////////////////////////////////////////////////
        let url = `${serverIp}/api/deleteSuscription`;
        fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // Include the token in the Authorization header
                'Authorization': `Bearer ${token}`,
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer",
            body: JSON.stringify({'emailToDelete':this.state.emailToDeleteInput}), // body data type must match "Content-Type"
            })
            .then(res=>res.json())
            .then((data)=>{
                if(data.response==="deletion_scheduled"){
                    this.checkAccountSubcription();
                    this.setState({sid:data.sid,pid:data.pid})
                }
            })
    }

    revertAccountCancelation(){
        document.getElementById('revert').style='display:None;'
        let token = localStorage.getItem('token');
        // Check if the token is not found
        if (!token) {token = sessionStorage.getItem('token');}
        ////////////////////////////////////////////////////
        let url = `${serverIp}/api/revertCancelation`;
        fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // Include the token in the Authorization header
                'Authorization': `Bearer ${token}`,
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer",
            body: JSON.stringify({'sid':this.state.sid,'pid':this.state.pid}), // body data type must match "Content-Type"
            })
            .then(res=>res.json())
            .then((data)=>{
                if(data.response==="reverted"){
                    this.checkAccountSubcription();
                }
            })
    }
    
    checkAndReplaceToken(tokenKey,newToken) {
        // Check localStorage
        let token = localStorage.getItem(tokenKey);
        if (token) {
          localStorage.setItem(tokenKey, newToken);
          return;
        }
      
        // Check sessionStorage
        token = sessionStorage.getItem(tokenKey);
        if (token) {
          sessionStorage.setItem(tokenKey, newToken);
        } 
      }
      


    checkAccountSubcription(){
        let token = localStorage.getItem('token');
        // Check if the token is not found
        if (!token) {token = sessionStorage.getItem('token');}
        ////////////////////////////////////////////////////
        let url = `${serverIp}/api/checkSuscription`;
        fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // Include the token in the Authorization header
                'Authorization': `Bearer ${token}`,
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer",
            body: JSON.stringify({'emailToDelete':this.state.emailToDeleteInput}), // body data type must match "Content-Type"
            })
            .then(res=>res.json())
            .then((data)=>{
                if(data.response===true||data.response===false){
                    this.setState({suscriptionIsCanceled:data.response,sid:data.sid})
                    try{document.querySelector("#root > div > div > section.accountPage > section > div.accountCancelationPage > button").style=""}
                    catch{}
                }
                else{
                    this.setState({suscriptionCheckError:data.response})
                }
            })
    }

    componentDidMount(){
        this.checkAccountSubcription()
    }

    accountCancelationWindowShow(){
        if(this.state.accountCancelationWindow==="closed"&&this.state.suscriptionIsCanceled===false){
            return(<div onClick={()=>this.handleAccountCancelationWindowOpen()} className='membershipButton membershipAbs'>{this.languageIndexer("Cancelar Subscripcion",'Cancel Subscription')}</div>);
            }
        else if(this.state.accountCancelationWindow==="open"&&this.state.suscriptionIsCanceled===false){
            return(
                <div className='accountCancelationPage'>
                    <div>{this.languageIndexer("Deseo Cancelar mi Membresia",'I want to cancel my membsership')} {this.props.gammaRegister}.</div>
                    <button onClick={
                        ()=>this.deleteAccountSubscription()}>CANCELAR</button>
                </div>
            );
        }
        else if(this.state.suscriptionIsCanceled===true){
            return(
                <div className='accountCancelationPage'>
                    <div>
                        {this.languageIndexer("Su suscription fue programada para cancelacion. Podra utilizar el acceso de membresia hasta el fin de periodo de cobro. No se le cobrara el proximo periodo.",'Your subscription has been scheduled for cancellation. You will be able to use your membership access until the end of the billing period. You will not be charged for the next period.')}
                    </div>
                    {/* <button id='revert' onClick={()=>this.revertAccountCancelation()}>Revertir cancelacion programada!</button> */}
                </div>
            );
        }
        else if(this.state.suscriptionCheckError!==""){
            return(<div className='accountCancelationPage'>{this.state.suscriptionCheckError}</div>)
        }
        else{
            return(
                <div className='accountCancelationPage'>
                    {this.languageIndexer("Favor esperar un momento...",'Please wait...')}
                </div>
            )
        }
    }

    render(){
        return(
            <section className='accountStatusContainer'>
                <div onClick={()=>this.props.closeDeleteAcct()} className='membershipButton membershipBack'>{this.languageIndexer("Atras",'Back')}</div>
                {this.accountCancelationWindowShow()}
            </section>
        );
    }
}

export default HandleAccountStatus;