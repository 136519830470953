import React from 'react';
import './searchBar.css';
import { Component } from 'react';


class Searchbar extends Component{
    constructor(){
        super();
        this.state={
            suggestions:[],
            toggleTrackStatus:'toggleTrack',
            leftText:'dissapear',
            rightText:'',
            toggleThumbClass:'toggleThumb2',
            toggleWrapperClass:'toggleWrapper',
            latitude:'',
            longitude:'',
        }
    }


    fetchLocationSuggestions(){
        if(window.location.protocol!=="file:"){
        let textInput=document.getElementById('locationInput').value
        fetch(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&text=${textInput}&maxSuggestions=6&countryCode=PRI&distance=50000`)
        .then(res=>res.json())
        .then(data=>{this.setState({suggestions:data.suggestions})})
        .catch(()=>{this.setState({suggestions:[]})})
        document.getElementById('suggestions').style="";}
    }

    handleLocationClick(data){
        let magicKey = data;
        fetch(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json&magicKey=${magicKey}`)
        .then(res=>res.json())
        .then(data=>this.props.sendDataToParent(data.candidates[0].location))
        .catch(()=>{this.setState({suggestions:[]})})
        document.getElementById('suggestions').style="display:None;";
        document.getElementById('locationInput').value="";
    }

    renderSuggestions(){
        try{
            if(this.state.suggestions.length>0&&document.getElementById("locationInput").value!==""){
                return(
                    this.state.suggestions.map((x)=>{
                        return(
                            <div onClick={(data)=>{this.handleLocationClick(data.target.id)}} className='listElement' id={x.magicKey} key={x.magicKey}>{x.text}</div>
                        );
                    })
                );
            }
            else{
                return ("");
            }
        }
        catch{}
    }

    searchIndexer(){
        if(this.state.toggleThumbClass==='toggleThumb2'){
        return(
            <div className='searchBar'>
                <input onChange={()=>this.fetchLocationSuggestions()} placeholder={this.props.searchPlaceholder} type="text" id='locationInput'/>
                <div className='suggestionsList' id="suggestions">
                     {this.renderSuggestions()}
                </div>
            </div>
        );
      }
      else{
        return(
            <div className='coordsSearch'>
                <div className='coordsSearchBox'>
                    <div className='numericField'>
                        <div className='numericInputText'>
                            {this.languageIndexer("Latitud",'Latitude')}
                        </div><input onChange={(data)=>{
                            this.setState({latitude:data.target.value})
                        }} min="-1000" max="1000" className='numericInputField' type='text'/>
                    </div>
                    <div className='numericField'>
                        <div className='numericInputText'>{this.languageIndexer("Longitud",'Longitude')}</div><input onChange={(data)=>{
                            this.setState({longitude:data.target.value})
                        }}
                        className='numericInputField' type='text'/>
                    </div>
                    <div className='numericField'>
                        <button onClick={()=>{
                            if((this.state.latitude!=="")&&(this.state.longitude!=="")){
                                try{this.props.sendDataToParent({y:Number(this.state.latitude),x:Number(this.state.longitude)})}catch{}
                            }
                        }} className='searchCoordsBtn'>
                            {this.languageIndexer("BUSCAR",'SEARCH')}
                        </button>
                    </div>
                </div>
            </div>
        );
      }
    }

    changeToggleStatus(){
        if(this.state.toggleTrackStatus==='toggleTrack'){
            this.setState({toggleTrackStatus:'toggleTrackDir',leftText:'',rightText:'dissapear',toggleThumbClass:'toggleThumb2Left',toggleWrapperClass:'toggleWrapper2'})
            if(window.innerWidth<481)
            {
            try{document.getElementsByClassName('membershipBox')[0].style='top:155px;';}catch{}
            }
        }
        else{
            this.setState({toggleTrackStatus:'toggleTrack',leftText:'dissapear',rightText:'',toggleThumbClass:'toggleThumb2',toggleWrapperClass:'toggleWrapper'})
            if(window.innerWidth<481){
            try{document.getElementsByClassName('membershipBox')[0].style='top:90px;';}catch{}
            }
        }
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    render(){
        return(
            <section
            onTouchStart={()=>this.props.holdTheMapStill()}
            onTouchEnd={()=>this.props.releaseTheMap()}
            >
                {this.searchIndexer()}
                <div className={this.state.toggleWrapperClass}>
                    <div onClick={()=>this.changeToggleStatus()} className={this.state.toggleTrackStatus}>
                        <div className={`coordsWord2 ${this.state.leftText}`}>
                            {this.languageIndexer("Direccion",'Address')}
                        </div>
                        <div className={`toggleThumb2 ${this.state.toggleThumbClass}`}></div>
                        <div className={`coordsWord ${this.state.rightText}`}>
                            {this.languageIndexer("Coordenadas",'Coordinates')}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Searchbar;
