import React,{Component} from 'react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "./BirthdayPicker";
// import BirthdayPicker from './BirthdayPicker';
import './SignUp.css';
import sendAnalytics from '../../Functions/analytics';
import { GoogleLogin } from '@react-oauth/google';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

class SignUp extends Component{
    constructor(props){
        super(props);
        this.state={
            renderIndex:'signUp',
            inputWidth:150,
            signUpName:'',
            signUpInitial:'',
            signUpLastName:'',
            signUpPhoneNumber:['','valid'],
            signUpGender:'',
            signUpEmail:['','invalid'],
            signupBirthdate:'',
            signUpPassword:'',
            signUpPasswordAgain:'',
            signUpStatus:'',
            serverIpAddress:this.props.serverIp,
            signUpShowOrHide:'show',
            termsAccepted:false,
            testedEmail:'',
            key:Date.now(),
            signupShow:'hide',
            fingerprint:''
        }
    }
    

    componentDidMount(){
        window.gtag('event','page_view',{'page_location':'Signup Page'});
        FingerprintJS.load().then(fp => fp.get()).then(result=>this.setState({fingerprint:result.visitorId}))
    }
    

    // handlePhoneNumber(data){
    //     const regex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    //     if(regex.test(data)){
    //         this.setState({signUpPhoneNumber:[data,'valid']})
    //     }
    //     else if(data===''){
    //         this.setState({signUpPhoneNumber:['','invalid']})
    //     }
    //     else{
    //         this.setState({signUpPhoneNumber:['','invalid']})
    //     }
    // }


    handleEmail(data){
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.setState({testedEmail:data})
        if(regex.test(data)){
            this.setState({signUpEmail:[data,'valid']})
        }
        else{
            this.setState({signUpEmail:['','invalid']})
        }
    }

    checkIfSubmitable(){
        if(this.state.signUpName==='' && this.props.selectedLanguage==='spanish'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","User Invalid Name Detected",String(new Date()))
            this.setState({signUpStatus:'Porfavor entra un nombre valido.',signUpShowOrHide:'show'})
        }
        else if(this.state.signUpName==='' && this.props.selectedLanguage==='english'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","User Invalid Name Detected",String(new Date()))
            this.setState({signUpStatus:'Please enter a valid name.',signUpShowOrHide:'show'})
        }
        else if(this.state.signUpLastName==='' && this.props.selectedLanguage==='spanish'){
            sendAnalytics("SIGNUP","User Invalid Last Name Detected",String(new Date()))
            this.setState({signUpStatus:'Poravor entra un apellido valido.',signUpShowOrHide:'show'})
        }
        else if(this.state.signUpLastName==='' && this.props.selectedLanguage==='english'){
            sendAnalytics("SIGNUP","User Invalid Last Name Detected",String(new Date()))
            this.setState({signUpStatus:'Please enter a valid last name.',signUpShowOrHide:'show'})
        }
        else if((this.state.signUpEmail[1]==='invalid'||this.state.signUpEmail[1]==='') && this.props.selectedLanguage==='spanish'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP",`User Invalid Email Detected ${this.state.testedEmail}`)
            this.setState({signUpStatus:'Porfavor entra un email valido.',signUpShowOrHide:'show'})
        }
        else if((this.state.signUpEmail[1]==='invalid'||this.state.signUpEmail[1]==='') && this.props.selectedLanguage==='english'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP",`User Invalid Email Detected ${this.state.testedEmail}`)
            this.setState({signUpStatus:'Please enter a valid email.',signUpShowOrHide:'show'})
        }
        else if(this.state.signUpPassword==='' && this.props.selectedLanguage==='spanish'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","User Invalid Password Detected",String(new Date()))
           
            this.setState({signUpStatus:'Porfavor entra un password valido.',signUpShowOrHide:'show'})
        }
        else if(this.state.signUpPassword==='' && this.props.selectedLanguage==='english'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","User Invalid Password Detected",String(new Date()))
            this.setState({signUpStatus:'Please enter a valid password.',signUpShowOrHide:'show'})
        }
        else if(this.state.signUpPasswordAgain!==this.state.signUpPassword && this.props.selectedLanguage==='spanish'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","Passwords did not match.",String(new Date()))
            this.setState({signUpStatus:'Los passwords deben ser iguales.',signUpShowOrHide:'show'})
        }
        else if(this.state.signUpPasswordAgain!==this.state.signUpPassword && this.props.selectedLanguage==='english'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","Passwords did not match.",String(new Date()))
            this.setState({signUpStatus:'Passwords must match.',signUpShowOrHide:'show'})
        }
        else if(this.state.termsAccepted!==true && this.props.selectedLanguage==='spanish'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","User did not accept terms.",String(new Date()))
            this.setState({signUpStatus:'Accepa los terminos y condiciones para proceder.',signUpShowOrHide:'show'})
        }
        else if(this.state.termsAccepted!==true && this.props.selectedLanguage==='english'){
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","User did not accept terms.",String(new Date()))
            this.setState({signUpStatus:'Please accept terms and conditions to proceed.',signUpShowOrHide:'show'})
        }
        else{
            //PAGE,ACTION,DATE
            sendAnalytics("SIGNUP","User signed up successfully.",String(new Date()))
            this.setState({
                signUpStatus:'',
                signUpReady:true
            })
            this.sendSignUpData()
        }
    }

    sendSignUpData(){
        fetch(`${this.state.serverIpAddress}/api/signup`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                Name:this.state.signUpName,
                Initial:this.state.signUpInitial,
                LastName:this.state.signUpLastName,
                PhoneNumber:this.state.signUpPhoneNumber[0],
                Gender:this.state.signUpGender,
                Email:this.state.signUpEmail[0],
                BirthDate:this.state.signupBirthdate,
                Password:this.state.signUpPassword,
                Fingerprint:this.state.fingerprint,
                tutorialprompt:false
            }) 
          })
          .then(response => {
            return response.json()
            })
          .then(response=>{
            if(response.ServerResponse==="Complete"){
                window.fbq('track', 'CompleteRegistration');
                this.setState({renderIndex:"account created"})
            }
            else{
                this.setState({signUpStatus:response.ServerResponse,signUpShowOrHide:'show'})
            }
            })
          .catch(err =>{
            this.setState({signUpStatus:err.ServerResponse,signUpShowOrHide:'show'})
            console.log('Solicitud fallida', err)
        }); // Capturar errores
    }

    onSuccess(response){
        fetch(`${this.state.serverIpAddress}/api/auth/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: response.credential, fingerprint:this.state.fingerprint, tutorialprompt:false }),
            })
            .then(response => response.json())
            .then(data => {
                sessionStorage.setItem('token', data.response);
                this.props.userIsLoggedIn() 
                this.props.userFirstLogin()
                sendAnalytics("SIGNUP","User signed up successfully.",String(new Date()))
            })
            .catch((error) => {
            console.error('Error:', error);
            });
      };
    
    onFailure(response){
        console.error('Login Failed:', response);
      };


    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    renderAlternateSignup(){
        if(this.state.signupShow!=='hide'){
            return(
                <section style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',}}>
                    <div className='signUpInput'>
                                    <div className='inputDescription'>
                                        {this.languageIndexer("Nombre",'Name')}
                                    </div>
                                    <div><input
                                    onChange={(data)=>{this.setState({signUpName:data.target.value})}}
                                    style={{width:this.state.inputWidth}} type='text'></input></div>
                                </div>
                                {/* <div className='signUpInput'>
                                    <div className='inputDescription'>Initial/Initial</div>
                                    <div style={{width:150,display:'flex'}}><input
                                    onChange={(data)=>{this.setState({signUpInitial:data.target.value})}}
                                    style={{width:50}}
                                    type='text'></input></div>
                                </div> */}
                                <div className='signUpInput'>
                                    <div className='inputDescription'>
                                        {this.languageIndexer("Apellido",'Last Name')}
                                    </div>
                                    <div><input 
                                    onChange={(data)=>{this.setState({signUpLastName:data.target.value})}}
                                    style={{width:this.state.inputWidth}} type='text'></input></div>
                                </div>
                                <div className='signUpInput'>
                                    <div className='inputDescription'>Email</div>
                                    <div><input 
                                    onChange={(data)=>{this.handleEmail(data.target.value)}}
                                    style={{width:this.state.inputWidth}}type='text'></input></div>
                                </div>
                                {/* <div className='signUpInput'>
                                    <div className='inputDescription'>Phone</div>
                                    <div><input
                                    onChange={(data)=>{this.handlePhoneNumber(data.target.value)}} 
                                    style={{width:this.state.inputWidth}} type='number'></input>*</div>
                                </div> */}
                                {/* <div className='signUpInput'>
                                    <div className='inputDescription'>Gender</div>
                                    <select 
                                        onChange={(data)=>{this.setState({signUpGender:data.target.value})}} 
                                        className='genderSelect'>
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>*
                                </div> */}
                                {/* <div className='signUpInput'>
                                    <div className='inputDescription'>Nacimiento</div>
                                    <div style={{marginLeft:-10}}>
                                        <BirthdayPicker
                                        onDateChange={(data)=>this.setState({signupBirthdate:data})}
                                        /></div>
                                </div> */}
                                <div className='signUpInput'>
                                    <div className='inputDescription'>Password</div>
                                    <div><input
                                    style={{width:150}}
                                    onChange={(data)=>this.setState({signUpPassword:data.target.value})}
                                    width={{width:this.state.inputWidth}} type='password'></input></div>
                                </div>
                                <div className='signUpInput'>
                                    <div className='inputDescription'>
                                        {this.languageIndexer("Re-Escribir Password",'Re-Write Password')}
                                    </div>
                                    <div><input 
                                    style={{width:150}}
                                    className='passwordInput'
                                    onChange={(data)=>this.setState({signUpPasswordAgain:data.target.value})}
                                    type='password'></input></div>
                                </div>
                                <div className='acceptInput'>
                                    <input onChange={(e)=>this.setState({termsAccepted:e.target.checked})} className='checkBoxInput' type='checkbox'></input>
                                    <div className='termsText'>
                                        {this.languageIndexer(
                                            "Accepto los terminos y condiciones de uso.",
                                            'I accept terms and conditions of use.'
                                            )}
                                    </div>
                                </div>
                                <div className='signUpStatus'>{this.state.signUpStatus}</div>
                                {this.signUpShowOrHide()}
                </section>
        );
      }
      else{
        return(
            <div className='registerShowBtn' onClick={()=>this.setState({signupShow:'show'})}>
                {this.languageIndexer("Registrarse con otro email!",'Register with other email!')}
            </div>
        );
      }
    }
    
    emailRenderIndex(){
        if(this.props.selectedLanguage==='spanish'){
            return(
                <div className='validationText'>
                    <length style={{color:'red'}}>Importante!:</length> Su cuenta ha sido exitosamente creada. Para garantizar su seguridad, hemos enviado un <length style={{color:'red'}}>código</length> de verificación a su dirección de <length style={{color:'red'}}>correo electrónico</length>. Este código es necesario para confirmar que el email proporcionado es suyo y será requerido únicamente la <length style={{color:'red'}}>primera vez</length> que acceda a su cuenta. Si no encuentra nuestro codigo en su bandeja de entrada, por favor revise su carpeta de "Spam" o "Correo no deseado".
                </div>
            );
        }
        else if(this.props.selectedLanguage==='english'){
            return(
                <div className='validationText'>
                    <length style={{color:'red'}}>Important!:</length> Your account has been successfully created. To ensure your security, we have sent a <length style={{color:'red'}}>verification code</length> to your <length style={{color:'red'}}>email address</length>. This code is necessary to confirm that the email provided is yours and will only be required the <length style={{color:'red'}}>first time</length> you log into your account. If you do not find our code in your inbox, please check your "Spam" or "Junk" folder.
                </div>
            );
        }
    }

    renderIndex(){
        if(this.state.renderIndex==='signUp'){
            return(
                <section className='loginPage'>
                    <div className='pageCloseContainer'>
                        <div onClick={()=>this.props.closeLoginWindow()} className='pageCloseBtn'>
                            <div className='xStick1'></div>
                            <div className='xStick2'></div>
                        </div>
                    </div>
                    <div className='loginContainerOuter'>
                        <div className='signUpContainer'>
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <div style={{color:'rgba(255,255,255,.7)'}}>
                                {this.languageIndexer("Registrarse con Gmail!",'Signup with Gmail!')}
                            </div>
                            <GoogleLogin
                                onSuccess={(res)=>this.onSuccess(res)}
                                onError={(res)=>this.onFailure(res)}
                                cookiePolicy={ 'single_host_origin' }
                                responseType='code,token'
                            />
                            <div style={{
                                height:2,
                                width:'100%',
                                background:'rgba(255,255,255,.7)',
                                marginTop:15
                            }}></div>
                            {this.renderAlternateSignup()}
                            </div>
                            
                        </div>
                    </div>  
                </section>
            );
        }
        else{
            return(
                <section className='loginPage'>
                <div className='pageCloseContainer'>
                    <div onClick={()=>this.props.closeLoginWindow()} className='pageCloseBtn'>
                        <div className='xStick1'></div>
                        <div className='xStick2'></div>
                    </div>
                </div>
                <div className='loginContainerOuter'>
                    <div className='notificationContainer'>
                        {this.emailRenderIndex()}
                        <button onClick={()=>{
                            //PAGE,ACTION,DATE
                            sendAnalytics("SIGNUP","Routed to login from signup.",String(new Date()))
                            this.props.switchToLoginWindow()
                            }} className='gotoLogin'>
                                {this.languageIndexer(
                                            "Entrar a cuenta",
                                            'Login to account'
                                            )}
                            </button>
                    </div>
                </div>  
            </section>
            );
        }
    }

    signUpShowOrHide(){
        if(this.state.signUpShowOrHide==='show' && this.props.selectedLanguage==='spanish'){
            return(<button onClick={()=>{
                this.setState({signUpShowOrHide:'hide'})
                this.checkIfSubmitable()
                }} className='signUpBtn'>
                    Crear Cuenta<br></br> <strong>GRATIS</strong></button>);
        }
        if(this.state.signUpShowOrHide==='show' && this.props.selectedLanguage==='english'){
            return(<button onClick={()=>{
                this.setState({signUpShowOrHide:'hide'})
                this.checkIfSubmitable()
                }} className='signUpBtn'><strong>SIGN UP!</strong></button>);
        }
        else{
            return(<div>Creando usuario porfavor espere...</div>);
        }
    }

    render(){
        return(this.renderIndex());
    }
}

export default SignUp;