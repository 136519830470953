import React from 'react';
import {Component} from 'react';
import './Tutorial.css';
class Tutorial extends Component{
    constructor(props){
        super(props);
        this.state={}
    }

    componentDidMount(){
        window.gtag('event','page_view',{'page_location':'Tutorial Page'})
    }

    languageIndexer(){
        if(this.props.selectedLanguage==='spanish'){
            return(
                <>
                <iframe className='tutorialVideo' height="315" style={{
                    marginTop:20,
                    width:'98%',
                    maxWidth:600
                    }} src="https://www.youtube.com/embed/xJjZ8fU-oTk?si=VE2v7_RodjqGtMfJ&vq=hd1080" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </>
            );
        }
        else if(this.props.selectedLanguage==='english'){return(
            <>
            <iframe className='tutorialVideo' height="315" style={{
                marginTop:20,
                width:'98%',
                maxWidth:600
                }} src="https://www.youtube.com/embed/mI_g0qG1WkE?si=ohftvfS85Dmy5Ofx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </>
        );}
    }

    render(){
        return(
            <section 
            onMouseDown={this.props.holdTheMapStill}
            onTouchStart={this.props.holdTheMapStill}
            onMouseUp={this.props.releaseTheMap}
            onTouchEnd={this.props.releaseTheMap}
            className='tutorialPage'>
                <div className='tutorialHeader'>
                    <div style={{marginLeft:10}}>Tutorial</div>
                    <div style={{marginRight:10}} onClick={()=>this.props.closeTutorialWindow()} className='closeBtn'>
                        Close
                    </div>
                </div>
                {this.languageIndexer()}
            </section>
        );
    }
}

export default Tutorial;