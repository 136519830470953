import React,{Component} from 'react';
import './changePw.css';
import serverIp from '../../../Functions/serverConfig';
class ChangePW extends Component{

    constructor(props){
        super(props);
        this.state={
            newPw:'',
            newPwRepeated:'',
            pwChangeState:''
        }
    }

    showOrHidePwChange(){
        if(this.state.pwChangeState==='Cambiando Contraseña porfavor espere un segundo.'){
            return('');
        }
        else{
            return(
                <div className='passwordChangeBtnContainer'>
                        <button style={{width:80}} onClick={()=>this.props.closeDeleteAcct()} className='passwordChangeBtn'>{this.languageIndexer("Atras",'Close')}</button>
                        <button onClick={()=>this.handlePasswordChange()} className='passwordChangeBtn'>{this.languageIndexer("Cambias Contraseña",'Change Password')}</button>
                </div>
            )
        }
    }

    handlePasswordChange(){
        if(
            this.state.newPw===this.state.newPwRepeated&&
           this.state.newPw!==""&&
           this.state.newPwRepeated!==""
           ){
            this.setState({pwChangeState:"Cambiando Contraseña porfavor espere un segundo."})
            let token = localStorage.getItem('token');
            // Check if the token is not found
            if (!token) {token = sessionStorage.getItem('token');}
            ////////////////////////////////////////////////////
            let url = `${serverIp}/api/changePw`;
            fetch(url,{
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                  'Content-Type': 'application/json',
                  // Include the token in the Authorization header
                  'Authorization': `Bearer ${token}`,
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer",
                // referrer:"https://catastro.crimpr.net/cdprpc/",
                // referrerPolicy:"no-referrer-when-downgrade",
                body: JSON.stringify({'newPw':this.state.newPw}), // body data type must match "Content-Type"
              })
              .then(res=>res.json())
              .then(data=>{
                if(!data.newToken){this.setState({pwChangeState:data.pwstate})}
                else{
                    if(localStorage.getItem('token')!=null){
                        localStorage.setItem('token',data.newToken)
                      }
                    else if(sessionStorage.getItem('token')!=null){
                    sessionStorage.setItem('token',data.newToken)
                    }
                    this.setState({pwChangeState:"Contraseña fue cambiada exitosamente."})
                }
              })
        }
        else{this.setState({pwChangeState:"Las contraseñas nuevas no son iguales o válidas."})}
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
      }

    render(){
        return(
            <section className='changePWOuter'>
                <div className='changePWBox'>
                    <div className='passwordChangeInput'>
                        <div>{this.languageIndexer("Nueva Contraseña",'New Password')}</div>
                        <input onChange={(e)=>this.setState({newPw:e.target.value})} type="password"></input>
                    </div>
                    <div className='passwordChangeInput'>
                        <div>{this.languageIndexer("Re-Escribir Nueva Contraseña",'Re-type New Password')}</div>
                        <input onChange={(e)=>this.setState({newPwRepeated:e.target.value})} type="password"></input>
                    </div>
                    {this.showOrHidePwChange()}
                    <div style={{color:'red'}}>{this.state.pwChangeState}</div>
                </div>
            </section>
        );
    }

}

export default ChangePW;