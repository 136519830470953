import {
	PayPalScriptProvider,
	PayPalButtons,
} from "@paypal/react-paypal-js";

import serverIp from '../../Functions/serverConfig';
import paymentConfig from '../../Functions/paymentVariables';
import sendAnalytics from "../../Functions/analytics";


const handleSubscriptionSuccess = (data,actions) =>{
	////////////////////////////////////////////////Get the token
	console.log(data.subscriptionID)
	let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    if (!token) {
      token = '';
    }
	///////////////////////////////////////////////Send subscription detalils to backend.
	fetch(`${serverIp}/api/paypalSubscribed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          subscriptionId: data.subscriptionID,
          'chosenLevel':'plusmax',
          // Include other subscription-related data as needed
        }),
      })
	  .then(res=>res.json())
	  .then(data=>{
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('token');
		sessionStorage.setItem('token', data.newToken);
		if (sessionStorage.getItem('token') === null) {
			sessionStorage.setItem('token', data.newToken);
		  }
		  window.location.reload();
	  })
}

const SubscriptionButtonWrapper = (props) => {
	return (<PayPalButtons
		createSubscription={(data, actions) => {
			return actions.subscription
				.create({
					plan_id: props.paypalSubscriptionID,
				});
		}}
		style={{
			label: "subscribe",
		}}
		onClick={()=>sendAnalytics("plusmaxpayform","Paypal payment attempted.",String(new Date()))}
		onApprove={(data,actions)=>{
			handleSubscriptionSuccess(data,actions);
			window.fbq('track', 'Purchase', {value: props.indexedAmmount, currency: 'USD'});
		}}
	/>);
}


export default function PaypalBtn(props) {
	return (
		<>
		<PayPalScriptProvider
			options={{
				"client-id": paymentConfig.paypalClientId,
				components: "buttons",
				intent: "capture",
				vault: true,
                disableFunding:"paylater,card"
			}}
		>
			<SubscriptionButtonWrapper paypalSubscriptionID={props.paypalSubscriptionID}/>
		</PayPalScriptProvider>
		</>
	);
}