import React,{Component} from 'react';
import './ForgotPassword.css'
import serverIp from '../../Functions/serverConfig';

class ForgotPassword extends Component{
    constructor(){
        super();
        this.state={
            pwResetWindowDisplay:'sendCode',
            messageAlert:'',
            email:'',
            code:'',
            sendCodeBtnMessage:'Enviar codigo a email',
            codigoCode:'codigo'
        }
    }

    componentDidMount(){
        if(this.props.selectedLanguage==='spanish'){
            this.setState({sendCodeBtnMessage:'Enviar codigo a email',codigoCode:'codigo'})
        }
        else{this.setState({sendCodeBtnMessage:'Send code to email',codigoCode:'code'})}
    }

    sendPasswordChange(){
        if(this.state.code===''&&this.state.email==='' && this.props.selectedLanguage==='spanish'){
            this.setState({messageAlert:'Porfavor entra un email y un codigo valido.'})
        }
        if(this.state.code===''&&this.state.email==='' && this.props.selectedLanguage==='english'){
            this.setState({messageAlert:'Please enter a valid email and validation code.'})
        }
        else if(this.state.code==='' && this.props.selectedLanguage==='spanish'){
            this.setState({messageAlert:'Porfavor entra un codigo valido.'})
        }
        else if(this.state.code==='' && this.props.selectedLanguage==='english'){
            this.setState({messageAlert:'Please enter a valid code.'})
        }
        else if(this.state.email==='' && this.props.selectedLanguage==='spanish'){
            this.setState({messageAlert:'Porfavor entra un email valido.'})
        }
        else if(this.state.email==='' && this.props.selectedLanguage==='english'){
            this.setState({messageAlert:'Please enter a valid email.'})
        }
        else{
            this.validateResetCode()
        }
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    genResetCode(){
        if(this.state.email===''&&this.props.selectedLanguage==='spanish'){
            this.setState({messageAlert:'Porfavor entra un email valido para enviar el codigo.'})
        }
        else if(this.state.email===''&&this.props.selectedLanguage==='english'){
            this.setState({messageAlert:'Please enter a valid email to recieve validation code.'})
        }
        else{
        if(this.props.selectedLanguage==='spanish'){this.setState({messageAlert:"Codigo nuevo fue enviado"})}
        else if(this.props.selectedLanguage==='english'){this.setState({messageAlert:"New code was sent"})}
        document.getElementById("codeInput").value=""
        fetch(`${serverIp}/api/resetCodeGen`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                email:this.state.email,
            }) 
          })
          .then(res=>res.json())
        }
    }

    validateResetCode(){
        if(this.state.email===''&&this.props.selectedLanguage==='spanish'){
            this.setState({messageAlert:'Porfavor entra un email valido para procesar el codigo.'})
        }
        else if(this.state.email===''&&this.props.selectedLanguage==='english'){
            this.setState({messageAlert:'Please enter a valid email to process validation code.'})
        }
        else{
        fetch(`${serverIp}/api/validateResetCode`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                email:this.state.email,
                code:this.state.code
            }) 
          })
          .then(res=>res.json())
          .then(data=>{
            if(typeof(data.response)==="string"){
                this.setState({messageAlert:data.response})
            }
            else{
                sessionStorage.setItem('token', data.response[0]);
                window.location.reload()
            }
          })
        }
    }

    indexRender(){
        if(this.state.pwResetWindowDisplay==='sendCode'){
            return(
                <section style={{
                    height:'100%',
                    width:'100%',
                    background:'rgba(0,0,0,.9)',
                    position:'absolute',
                    zIndex:9999
                    }} className='forgotContainer'>
                    <div className='pageCloseContainer'>
                        <div onClick={()=>this.props.closeLoginWindow()} className='pageCloseBtn'>
                            <div className='xStick1'></div>
                            <div className='xStick2'></div>
                        </div>
                    </div>
                    <div className='forgotForm'>
                        <div className='forgotFormInner'>
                            <div>Email</div>
                            <input id="emailInput" onChange={(data)=>this.setState({email:data.target.value})} type='email' placeholder='email' className='emailInput'></input>
                            <button onClick={()=>this.genResetCode()} className='codeSendBtn'>{this.state.sendCodeBtnMessage}</button>
                            <div style={{margin:5}}>
                                {this.languageIndexer(
                                    "A este email será enviado un codigo de validacion con expiracion de 15 minutos. De no verlo en su inbox porfavor revisar la bandeja de junk.",
                                    "A validation code with a 15-minute expiration will be sent to this email. If you don't see it in your inbox, please check the junk folder."
                                )}
                            </div>
                            <input id="codeInput" onChange={(data)=>this.setState({code:data.target.value})} placeholder={this.state.codigoCode} className='emailInput'></input>
                            <button style={{padding:5}} onClick={()=>this.sendPasswordChange()} className='codeSendBtn'>
                            {this.languageIndexer(
                                    "Entrar a cuenta",
                                    "Login"
                                )}
                            </button>
                            <div style={{height:21,color:'red'}}>{this.state.messageAlert}</div>
                        </div>
                    </div>
                </section>
                );
        }
    }

    render(){
        return(this.indexRender());
    }
}

export default ForgotPassword;