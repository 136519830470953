import React from 'react';
import {Component} from 'react';
import './Terms.css';


class Terms extends Component{
    render(){
        return(
            <section
            onTouchStart={()=>this.props.holdTheMapStill()}
            onTouchEnd={()=>this.props.releaseTheMap()}
            className='termsWindow'>
                <section className='termsInner'>
                    <div className='closeHeader'>
                        <button style={{marginTop:10}} onClick={()=>this.props.closeTerms()}>Cerrar</button>
                    </div>
                    <div className='mainContentTerms'>
                        <div className='termsTitle'>Términos y Condiciones de Uso</div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            1. Términos y Condiciones de Uso en General
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>Estos “Términos y Condiciones de Uso” rigen el uso del sitio web www.casadata.io (en adelante, “Sitio Web”) operado por Casadata.io (en adelante, “Casadata.io” o la “Corporación”). Al acceder o utilizar el Sitio Web, usted (en adelante, “Usuario”) acepta cumplir y estar sujeto a estos Términos y Condiciones de Uso en su totalidad.</li>
                            </ul> 
                            <ul>
                                <li>Será responsabilidad del Usuario el leer los Términos y Condiciones de Uso previo a utilizar los servicios ofrecidos en el Sitio Web. En caso de que el Usuario no desee estar sujeto a los Términos y Condiciones de Uso, no debe usar o acceder el Sitio Web o de forma alguna utilizar los servicios que se ofrecen en éste.</li>
                            </ul> 
                            <ul>
                                <li>Los Términos y Condiciones de Uso pueden ser revisados ocasionalmente. Casadata.io se reserva el derecho de proporcionarle al Usuario un aviso razonable sobre los cambios materiales, ya sea publicando los cambios en el Sitio Web o enviando una comunicación por correo electrónico. El Usuario reconoce que el uso continuo del Sitio Web después de que los Términos y Condiciones de Uso entren en vigencia constituirá su aceptación de estos.</li>
                            </ul> 
                            <ul>
                                <li>Casadata.io recomienda la revisión periódica de la versión actualizada de los Términos y Condiciones de Uso.</li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            2. Propósito y Uso de Contenido
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>El Sitio Web está diseñado con el propósito de proveer información actualizada sobre el mercado de bienes raíces residenciales en Puerto Rico (en adelante, el “Servicio” o “Servicios”). La información que se ofrece en el Sitio Web es obtenida y recopilada de múltiples fuentes, incluyendo datos, información de dominio público e informes públicos provistos por agencias gubernamentales especializadas en el sector de bienes raíces en Puerto Rico.</li>
                            </ul> 
                            <ul>
                                <li>La Corporación no garantiza la exactitud o integridad de la información y se recomienda validar cualquier información de interés con las fuentes primarias antes de tomar decisiones basadas en su contenido.</li>
                            </ul> 
                            <ul>
                                <li>El contenido proporcionado en el Sitio Web y como parte de los Servicios es provisto únicamente con fines informativos y referenciales, y no constituye asesoramiento profesional. La información provista en el Sitio Web y como parte de los Servicios tampoco busca reemplazar el criterio o la validación de profesionales en el sector inmobiliario residencial de Puerto Rico.</li>
                            </ul>
                            <ul>
                                <li>El Usuario acepta que los Servicios y la información contenida en el Sitio Web son ofrecidos por la Corporación tal y como surge de las bases de datos públicas de donde provienen, y que Casadata.io no se responsabiliza ni garantiza la precisión o confiablidad de su contenido.</li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            3. Suscripción
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>El Usuario reconoce y acepta que los Servicios requieren una suscripción pagada y que las suscripciones se ofrecen mensualmente. Al final de cada periodo de suscripción ésta será renovada, a menos que el Usuario la cancele a través de su cuenta. </li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce y acepta que todas las tarifas se indican en dólares estadounidenses y Casadata.io se reserva el derecho de cambiar sus tarifas en cualquier momento.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce que su suscripción puede cancelarse en cualquier momento, sujeto a las siguientes condiciones:</li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Al cancelar la suscripción, el Usuario no recibirá reembolso alguno y su suscripción será suspendida inmediatamente. 
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Si el Usuario desea mejorar su membresía actual, deberá abonar la diferencia entre la membresía corriente y la que desea adquirir. La duración de la membresía se ajustará para que inicie desde el momento en que el cambio de membresía es efectivo. El cambio considerará efectivo una vez el importe de la diferencia entre membresías sea pagado a Casadata.io.  
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Se recomienda al Usuario que realice estos cambios al final del mes para maximizar el aprovechamiento de los Servicios y del Sitio Web. 
                                </li>
                            </ul>
                            <ul>
                                <li>El Usuario reconoce que debe obtener una cuenta que le permita suscribirse y acceder a los Servicios. Al crear su cuenta, el Usuario acepta proporcionar información precisa, veraz y completa. Además, el Usuario reconoce es el único responsable de cualquier actividad que ocurra bajo su cuenta y acepta que su información de cuenta, incluyendo credenciales de acceso y contraseña, debe mantenerse segura.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario acepta y reconoce que en el uso de los Servicios o del Sitio Web no podrá:</li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Copiar, modificar, transmitir o distribuir contenido del Sitio Web o de la programación de este que pertenezca a Casadata.io. 
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Acceder, alterar o utilizar cualquier parte de los Servicios o del Sitio Web a la que no está autorizado a acceder.  
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Intentar a través de cualquier medio probar la vulnerabilidad de cualquier sistema o red relacionado con los Servicios o el Sitio Web.  
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Violar cualquier medida de seguridad o autenticación relacionada con el Servicio o el Sitio Web.   
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Eliminar, desactivar, evitar o deteriorar cualquier tecnología que controle el acceso al Servicio o al Sitio Web.   
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Realizar ingeniería inversa o desensamblar cualquier aspecto de los Servicios o del Sitio Web.   
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Llevar a cabo actos de reproducción, venta, reventa o copia de cualquier segmento de los Servicios, su uso o acceso a estos.  
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Suplantar o falsificar la identidad de cualquier persona natural o jurídica para acceder a los Servicios o al Sitio Web.  
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Cometer fraude a través del Sitio Web o de los Servicios.   
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Violar cualquier ley aplicable a través del uso de los Servicios o del Sitio Web.   
                                </li>
                            </ul> 
                            <ul>
                                <li>Casadata.io se reserva el derecho de cancelar o suspender la suscripción del Usuario y todo acceso al Sitio Web en caso de que entienda que los Términos y Condiciones de Uso han sido violados.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce y acepta que los Servicios y la información que surge del Sitio Web se proporciona “tal cual” y “según disponibilidad” sin garantía alguna. Casadata.io renuncia a todas las garantías, incluyendo las expresas, implícitas o legales.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce que Casadata.io no es responsable por ningún daño indirecto, especial, consecuente, ejemplar o incidental que surja del uso o inhabilidad para acceder o utilizar los Servicios o el Sitio Web.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce y acepta que las suscripciones a los Servicios se pagan a través de terceros que incluyen Stripe y PayPal (las “Plataformas de Pago”).</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce y acepta que Casadata.io no almacena números de tarjeta de crédito ni información relacionada a ésta. Además, el Usuario reconoce y acepta que una vez se efectué el pago de la membresía, este no será reembolsable.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce y acepta que las membresías continuarán siendo facturadas mensualmente en la plataforma seleccionada al momento de realizar el primer pago.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce que Casadata.io no será responsable de indemnizar al Usuario de: i) cualquier pérdida, daño o reclamación que pueda surgir de o en conexión con cualquier incumplimiento por parte de las Plataformas de Pago de cualesquiera de sus representaciones, garantías u obligaciones para con el Usuario, ii) cualquier reclamación de terceros relacionada con los servicios de las Plataformas de Pago; iii) reclamaciones, pérdidas, daños relacionadas con acciones u omisiones de las Plataformas de Pago en sus funciones o en los servicios que provee al Usuario; o iv) cualquier violación de las leyes aplicables por parte de las Plataformas de Pago.</li>
                            </ul> 
                            <ul>
                                <li>Casadata.io se reserva el derecho de comenzar contra el Usuario cualquier acción legal que pudiera corresponder, a discreción exclusiva de la Corporación para preservar su seguridad e integridad, en caso de que éste/a proporcione datos inexactos, incompletos, engañosos o falsos.</li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            4. Derechos Propietarios de Casadata.io y Propiedad Intelectual
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>El Usuario reconoce y acepta que los Servicios, el Sitio Web y el software esencial (“Software”) proporcionado por Casadata.io contienen o pudieran contener material confidencial protegido por derechos de propiedad intelectual de Puerto Rico, de los Estados Unidos y de cualesquiera otras leyes aplicables.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario también reconoce que cualquier contenido en anuncios o información obtenida a través de los Servicios, o de los anunciantes, está protegido por derechos de autor, marcas registradas, patentes u otros derechos y leyes.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario acepta y reconoce que salvo que se indique expresamente en el Sitio Web, no puede copiar, reproducir, publicar, transmitir o crear obras derivadas del contenido del Sitio Web o del Software sin autorización expresa de la Compañía. El Usuario no debe modificar el contenido de los Servicio, del Software o del Sitio Web sin autorización de Casadata.io.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce que el Sitio Web o los Servicios pudieran contener o referirse a información de dominio público que no está sujeta a protección de propiedad intelectual. El Usuario acepta hacer uso razonable de la referida información pública, siempre y cuando su uso cumpla con las leyes y reglamentos del Estado Libre Asociado de Puerto Rico, Estados Unidos y cualquier otra ley de privacidad y protección de datos vigente y aplicable.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce que todas las marcas, nombres comerciales, logos y otros distintivos de Casadata.io son de su propiedad exclusiva y que acepta no usar dichas marcas y distintivos sin el consentimiento previo de la Compañía, en cumplimiento con las leyes de propiedad intelectual de Puerto Rico, Estados Unidos, y cualesquiera otras leyes que versen sobre el uso de marcas registradas. Además, el Usuario se responsabiliza en cumplir con toda ley y reglamento aplicable en materia de privacidad de datos del Estado Libre Asociado de Puerto Rico, Estados Unidos, y cualesquiera otras leyes aplicables.</li>
                            </ul> 
                            <ul>
                                <li>Casadata.io respeta los derechos de propiedad intelectual de terceros y requiere lo mismo por parte del Usuario. En caso de que el Usuario entienda que el Sitio Web o los Servicios violan sus derechos de autor, debe notificar inmediatamente a la Compañía a: information@casadata.io. La Compañía se reserva el derecho de suspender, inhabilitar o cancelar la cuenta, suscripción o el acceso del Usuario que viole o atente contra la propiedad intelectual de terceros.</li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            5. Política de Privacidad
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>La Política de Privacidad de Casadata.io, rige la recopilación, el uso y la protección de la información personal de los usuarios. Al utilizar el Sitio Web, los usuarios aceptan los términos descritos en la Política de Privacidad.</li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            6. Renuncia de Responsabilidad y Limitación de Daños
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>Al acceder o utilizar los Servicios o el Sitio Web, el Usuario renuncia a cualquier reclamación contra Casadata.io, sus directores, empleados, agentes y sucesores que surja de o en conexión con su uso del Sitio Web. Esta renuncia incluye, pero no se limita a, reclamaciones por daños directos o indirectos, pérdidas o perjuicios de cualquier otra índole, incluyendo, pero no limitándose a, pérdida de beneficios, interrupción de negocio, pérdida de información comercial o cualquier otra pérdida pecuniaria.</li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            7. Ley Aplicable, Resolución de Disputas y Término Prescriptivo
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>El Sitio Web y los Servicios son controlados y operados por Casadata.io desde sus oficinas en Puerto Rico. El Usuario acepta que cualquier asunto legal, disputa, reclamación o acción relacionada con el uso del Sitio Web o de los Servicios será regulada por las leyes del Estado Libre Asociado de Puerto Rico.</li>
                            </ul> 
                            <ul>
                                <li>Cualquier disputa relacionada con estos Términos y Condiciones de Uso se someterá a la jurisdicción exclusiva de los tribunales del Estado Libre Asociado de Puerto Rico y cualquier causa de acción, reclamación o disputa que surja del uso del Sitio Web o de los servicios deberán ser radicadas o presentadas en las cortes con jurisdicción en el Tribunal de Primera Instancia de Caguas o la Corte de Distrito para el Distrito de Puerto Rico.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario y Casadata.io acuerdan y reconocen someterse a la jurisdicción de los tribunales antes mencionados y renuncian a toda objeción en el ejercicio de la jurisdicción sobre las partes por parte de dichos tribunales.</li>
                            </ul> 
                            <ul>
                                <li>El Usuario reconoce y acepta que cualquier reclamación o causa de acción que surja del uso del Sitio Web o del Servicio, ésta debe ser presentada ante los foros mencionados anteriormente durante el término prescriptivo de un (1) año. De no presentarse dentro del referido término, la reclamación o causa de acción prescribirá y nunca podrá ser reclamada por el Usuario.</li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            8. Información de Contacto
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>Para consultas o inquietudes sobre estos términos y condiciones o el uso del sitio web, los usuarios puede contactarnos en: information@casadata.io.</li>
                            </ul> 
                        </div>
                    </div>
                    <div style={{color:'black',textAlign:'center'}}>Copyright © CASADATA LLC 2024 All Rights Reserved</div>
                </section>
            </section>
        );
    }
}

export default Terms;