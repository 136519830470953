import React from 'react';
import {Component} from 'react';
import './../Terms/Terms.css';

class Privacy extends Component{
    render(){
        return(
            <section
            onTouchStart={()=>this.props.holdTheMapStill()}
            onTouchEnd={()=>this.props.releaseTheMap()}
            className='termsWindow'>
                <section className='termsInner'>
                    <div className='closeHeader'>
                        <button style={{marginTop:10}} onClick={()=>this.props.closeTerms()}>Cerrar</button>
                    </div>
                    <div className='mainContentTerms'>
                        <div className='termsTitle'>Política de Privacidad</div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            INTRODUCCIÓN 
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>
                                Esta Política de Privacidad se aplica al sitio web de la Casadata.io (“Casadata” o la “Compañía”) ubicado en la dirección www.casadata.io, y a todos los sitios web, aplicaciones y servicios relacionados (en conjunto, el "Sitio Web"). 
                                Casadata está comprometida con la protección de la privacidad y seguridad de su información personal. A continuación, se describe la información que recopilamos de usted y sobre usted (el “Usuario”) cuando visita e interactúa con nuestro Sitio Web, utiliza los servicios que ofrecemos y/o adquiere la suscripción mensual que ofrecemos (el “Servicio” o los “Servicios”). 
                                Además, en la Política de Privacidad se identifica cómo Casadata utiliza la información recopilada y las opciones del Usuario con respecto a esa información, conforme proveen las leyes y reglamentaciones aplicables, y dependiendo de cómo el Usuario interactúe con nuestro Sitio Web o Servicios y de dónde se encuentre el Usuario ubicado en el mundo.
                                </li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            PRINCIPIOS DE PROTECCIÓN DE DATOS
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>Casadata está comprometida con dar fiel cumplimiento a los principios que dispone el General Data Protection Regulation (“GDPR”) para el procesamiento y protección de data personal del usuario. Por ello:</li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    La recopilación de los datos personales del Usuario se hará de manera legal, justa y transparente, y el Usuario será informado sobre la recopilación de datos, los fines para los que son recopilados y cualquier otro asunto relevante a la recopilación.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Los datos personales del Usuario serán recopilados con fines legítimos, específicos y explícitos, y no serán procesados o recopilados de manera incompatible a estos principios.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Casadata solo recopilará datos personales estrictamente necesarios para cumplir con los propósitos para los cuales son recopilados.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Casadata se compromete a mantener los datos personales del Usuario de manera precisa y actualizada.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Casadata implementará las medidas de seguridad adecuadas para proteger los datos personales de los usuarios, de manera que no sea accedida, divulgada, alterada o destruida sin autorización.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Casadata siempre respetará los derechos de acceso, rectificación, oposición, supresión, limitación del tratamiento y portabilidad de los datos personales del Usuario.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Casadata se compromete a no conservar los datos personales del Usuario que permitan su identificación por más del tiempo necesario para los fines para los cuales se procesan o recopilan.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Casadata se compromete a demostrar su cumplimiento con los principios de protección de datos y, en caso de una investigación, cooperar con las autoridades correspondientes.
                                </li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            ¿QUÉ INFORMACIÓN RECOPILAMOS? 
                        </div>
                        <div style={{fontSize:13,paddingLeft:60,width:'80%',marginTop:8}}>
                            <ul>
                                <li>Recopilamos los siguientes tipos de información de y sobre usted:</li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Información Personal: Información personal identificable ("IPI") directamente del Usuario, como su nombre, dirección de correo electrónico y número de teléfono.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Información sobre sus interacciones con nuestro Sitio Web, como las páginas que visita, los enlaces en los que hace clic y las búsquedas que realiza.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Información del dispositivo, como su dirección IP, tipo de navegador, sistema operativo e identificador de dispositivo.
                                </li>
                                <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                    Otra información: Información no personal identificable ("No-IPI") que se recopila automáticamente cuando visita nuestro Sitio Web, como su historial de navegación, consultas de búsqueda y datos de cookies. Son datos agregados que se derivan de su Información Personal y No-IPI de una manera que no lo identifica individualmente.
                                </li>
                            </ul> 
                        </div>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            RETENCIÓN DE SU INFORMACIÓN      
                        </div>
                        <ul>
                            <li>Mantendremos su información durante el tiempo necesario para cumplir con los propósitos para los cuales fue recopilada y para cualquier requisito legal o regulatorio. Luego eliminaremos su información de manera segura. El tiempo en que la información será retenida se determinará en base a los siguientes criterios:</li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                El tiempo en que la información del Usuario sea relevante.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                El tiempo que sea razonable preservar récords para que Casadata demuestre que ha cumplido a cabalidad con sus deberes y obligaciones.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                El tiempo de duración de cualquier periodo en el cual se pueda entablar una reclamación.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                El tiempo de duración de cualquier periodo establecido por ley o recomendado por reguladores, profesionales o asociaciones de profesionales.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                El acuerdo entre Casadata y el Usuario, la existencia del consentimiento del Usuario, y el interés legitimo de la Compañía de mantener la información conforme se dispone en la Política de Privacidad. 
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            ¿CÓMO USAMOS SU INFORMACIÓN?      
                        </div>
                        <ul>
                            <li>Utilizamos su información para los siguientes propósitos:</li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para operar el Sitio Web y ofrecer los servicios que solicite.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para facilitar los Servicios y mejorar su efectividad.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para enviarle información sobre los Servicios y la suscripción del Usuario. 
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para personalizar la experiencia del Usuario en el Sitio Web.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para mejorar el Sitio Web y los Servicios, y desarrollar nuevas funciones y servicios. 
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para cumplir con las leyes y regulaciones aplicables.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para proteger la seguridad y la integridad del Sitio Web.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            ¿CÓMO COMPARTIMOS SU INFORMACIÓN?       
                        </div>
                        <ul>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Casadata no comparte información personal identificable con terceros sin autorización previa del Usuario, excepto para cumplir con leyes, reglamentos o procesos legales, según apliquen, o para la protección de los derechos y seguridad de terceros. 
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                No obstante, Casadata puede compartir con terceros información agregada y anonimizada del Usuario de manera para fines publicitarios y análisis. 
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Casadata utiliza compañías como Stipe y Paypal para procesar y facturar los pagos con tarjeta de crédito de las suscripciones mensuales del Usuario. Casadata no almacena la información de la tarjeta de crédito utilizada por el Usuario. 
                            </li>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Casadata también podrá divulgar a terceros la información provista por el Usuario para dar cumplimiento a los Términos y Condiciones de Uso, para proteger, mantener y preservar los derechos y la propiedad de la Companía u otros propósitos.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            NOTIFICACIÓN A USUARIO DE LA UNIÓN EUROPEA      
                        </div>
                        <ul>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                               Primordialmente, las operaciones de Casadata y los Servicios se ofrecen en Puerto Rico y en los Estados Unidos de América. Si el Usuario europeo nos proporciona información, ésta será transferida fuera de Europa a Puerto Rico y los Estados Unidos de América. A partir del 1 de agosto de 2016, la decisión de adecuación sobre Privacidad UE-E.E.U.U. se volvió operativa. Bajo este marco, se protegen los derechos fundamentales de cualquier Usuario en la Unión Europea cuyos datos personales se transfieren a los Estados Unidos de América con fines comerciales y dicha decisión permite la transferencia gratuita de los datos a empresas certificadas en los Estados Unidos bajo el Privacy Shield. Al proporcionarnos información personal, el Usuario está consintiendo a su almacenamiento y uso según se describe en la Política de Privacidad. 
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            USUARIO DEL ESTADO DE CALIFORNIA      
                        </div>
                        <ul>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                            Casadata reconoce y respeta los derechos de privacidad del Usuario de California conforme dispone la Ley de Privacidad del Consumidor de California (“CCPA” por sus siglas en inglés). Casadata recopilará información personal del usuario según se define en la CCPA para mejorar los Servicios y la experiencia del Usuario. 
                            La información recopilada puede incluir: información personal identificable y no identificable, tales como identificadores, características personales información comercial y actividad en línea. 
                            El Usuario puede solicitar que Casadata le proporcione información sobre las categorías y las fuentes de la información recopilada, los fines y el uso de la recopilación y del procesamiento de la información y que se divulguen la identidad de los terceros con los que se comparten los datos del usuario. 
                            El usuario tiene derecho a solicitar que se elimine la información recopilada, con algunas excepciones, y Casadata no discriminará al Usuario que ejerza sus derechos conforme a la CCPA.
 
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            TRANSFERENCIAS INTERNACIONALES DE DATA PERSONAL DEL USUARIO      
                        </div>
                        <ul>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                                Para proporcionar los Servicios, Casadata pudiera transferir datos personales del Usuario a países distintos al país en el cual los datos fueron originalmente recopilados. En ese caso, Casadata protegerá la transferencia de datos personales a otros países conforme se describe en la Política de Privacidad y la Compañía cumplirá con los requisitos legales que apliquen para dicha transferencia.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            ¿CUÁLES SON LOS DERECHOS DEL USUARIO?
                        </div>
                        <ul>
                            <li style={{listStyleType:'square',marginLeft:30, marginTop:5}}>
                            Bajo las leyes de protección de data, el Usuario tendrá derecho a: acceder a su data personal; a rectificar la data personal si es inexacta o está incompleta; a solicitar la eliminación o que se borre su data personal; a restringir el procesamiento o recopilación de su data personal; a portar su data personal y; a objetar el procesamiento o recopilación de su data personal.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO
                        </div>
                        <ul>
                            <li style={{fontSize:13,marginLeft:60,width:'80%',marginTop:8}}>
                                Utilizamos cookies y otras tecnologías de seguimiento (como píxeles y etiquetas web) para recopilar información sobre su actividad de navegación en el Sitio Web. Las cookies son pequeños archivos de datos que se almacenan en su dispositivo cuando visita un sitio web. Nos permiten recordar sus preferencias y rastrear su actividad en el Sitio Web. También podemos utilizar otras tecnologías de seguimiento para recopilar información sobre su actividad de navegación. Puede controlar el uso de cookies ajustando la configuración de su navegador. Sin embargo, si desactiva las cookies, es posible que no pueda utilizar ciertas funciones del Sitio Web o de los Servicios.
                            </li>
                            <li style={{fontSize:13,marginLeft:60,width:'80%',marginTop:8}}>
                                En el Sitio Web, Casadata utiliza principalmente tres (3) tipos de cookies:
                            </li>
                            <li style={{listStyleType:'square',marginLeft:100, marginTop:5}}>
                                Funcionales: son las cookies necesarias para el funcionamiento básico del Sitio Web, permiten recordar las preferencias del Usuario y resultan necesarias para la seguridad del Sitio Web.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:100, marginTop:5}}>
                                Rendimiento: son las cookies que mejoran el funcionamiento del Sitio Web y las que asisten a Casadata a acelerar el proceso de solicitudes del Usuario y recordar sus preferencias. Rechazar las cookies de rendimiento pueden afectar las funciones del Sitio Web y los Servicios.
                            </li>
                            <li style={{listStyleType:'square',marginLeft:100, marginTop:5}}>
                                Redes Sociales y Publicidad: las cookies las de redes sociales son las que permiten la conexión con las redes sociales del Usuario. Por su parte, las cookies de publicidad recopilan información para personalizar los anuncios dentro y fuera del Sitio Web. Su rechazo puede redundar en que el Usuario reciba anuncios menos relevantes y el Usuario podría enfrentar dificultad al intentar enlazar el Sitio Web con sus redes sociales.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            SEGURIDAD DE LOS DATOS, BRECHAS DE DATA Y USO NO COMERCIAL DE LOS DATOS
                        </div>
                        <ul>
                            <li style={{fontSize:13,marginLeft:60,width:'80%',marginTop:8}}>
                            Tomamos medidas razonables para proteger su información contra el acceso, uso, divulgación, alteración o destrucción no autorizados. En el evento de que ocurra una brecha de datos, Casadata notificará oportunamente a la autoridad correspondiente y al Usuario afectado siguiendo nuestras obligaciones legales. Casadata mantiene y se reserva el derecho de contactar al Usuario en caso de que sea necesario para fines no comerciales o no relacionados al marketing, como alertas de errores, violaciones de seguridad, problemas en la cuenta del Usuario o en su suscripción, y/o cambios en los Servicios. En ciertas circunstancias, Casadata podrá utilizar el Sitio Web u otros medios públicos para dar notificación al Usuario sobre estos asuntos no comerciales o no relacionados al marketing.

                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            ENLACES A OTROS SITIOS WEB 
                        </div>
                        <ul>
                            <li style={{fontSize:13,marginLeft:60,width:'80%',marginTop:8}}>
                            Nuestro Sitio Web puede contener enlaces a otros sitios web que no son propiedad ni están operados por Casadata.com. No somos responsables de las prácticas de privacidad de otros sitios web. Le recomendamos leer las políticas de privacidad de cualquier otro sitio web que visite.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            REGISTRO MEDIANTE LOS SERVICIOS DE GOOGLE 
                        </div>
                        <ul>
                            <li style={{fontSize:13,marginLeft:60,width:'80%',marginTop:8}}>
                            Casadata se compromete a no compartir los datos recopilados a través de los servicios de Google utilizados para acceder a la aplicación o para el proceso de registro. Dichos datos serán empleados exclusivamente para la creación de cuentas, permitiendo así que las transacciones sean referenciadas tanto por el usuario como por los sistemas internos de Casadata. Este uso está limitado a asegurar la funcionalidad adecuada de la aplicación.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD 
                        </div>
                        <ul>
                            <li style={{fontSize:13,marginLeft:60,width:'80%',marginTop:8}}>
                            Podemos actualizar esta política de privacidad periódicamente. La política actualizada se publicará en nuestro sitio web con una fecha efectiva revisada y le proporcionaremos un aviso mediante el correo electrónico que provea como parte del uso del Sitio Web o de los Servicios. Cuando sea legalmente necesario, Casadata solicitará el consentimiento del Usuario antes de llevar a cabo cambios a la Política de Privacidad.
                            </li>
                        </ul>
                        <div style={{fontSize:13,paddingLeft:30}}>
                            CONTÁCTENOS  
                        </div>
                        <ul>
                            <li style={{fontSize:13,marginLeft:60,width:'80%',marginTop:8}}>
                                Si tiene alguna pregunta o inquietud sobre esta política de privacidad o nuestras prácticas, contáctenos a través de <strong>information@casadata.io</strong> La Política de Privacidad considera los requerimientos del GDPR y cualquier otra ley estatal, federal o internacional aplicable al manejo, procesamiento y recopilación de data del Usuario. Mediante el uso del Sitio Web, el Usuario acepta los Términos y Condiciones de Uso y la presente Política de Privacidad. De no estar de acuerdo con los Términos y Condiciones de Uso y la Política de Privacidad, el Usuario debe abstenerse de utilizar el Sitio Web y los Servicios.
                            </li>
                        </ul>
                    </div>
                    <div style={{color:'black',textAlign:'center'}}>Copyright © CASADATA LLC 2024 All Rights Reserved</div>
                </section>
            </section>
        );
    }
}

export default Privacy;