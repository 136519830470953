import {
	PayPalScriptProvider,
	PayPalButtons,
} from "@paypal/react-paypal-js";
import serverIp from '../../Functions/serverConfig';
import paymentConfig from '../../Functions/paymentVariables';



const handleSubscriptionSuccess = (data,actions) =>{
	////////////////////////////////////////////////Get the token
	let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    if (!token) {
      token = '';
    }
	///////////////////////////////////////////////Send subscription detalils to backend.
	fetch(`${serverIp}/api/paypalSubscribed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          subscriptionId: data.subscriptionID,
          'chosenLevel':'plus',
          // Include other subscription-related data as needed
        }),
      })
	  .then(res=>res.json())
	  .then(data=>{
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('token');
		sessionStorage.setItem('token', data.newToken);
		if (sessionStorage.getItem('token') === null) {
			sessionStorage.setItem('token', data.newToken);
		  }
		  window.location.reload();
	  })
}

const SubscriptionButtonWrapper = () => {
	return (<PayPalButtons
		createSubscription={(data, actions) => {
			window.fbq('track', 'Purchase', {value: 5.00, currency: 'USD'});
			return actions.subscription
				.create({
					plan_id:paymentConfig.paypalPlusPlanId,
				});
		}}
		style={{
			label: "subscribe",
		}}
		onApprove={(data,actions)=>{
			handleSubscriptionSuccess(data,actions);
		}}
	/>);
}


export default function PaypalBtn() {
	return (
		<>
		<PayPalScriptProvider
			options={{
				"client-id": paymentConfig.paypalClientId,
				components: "buttons",
				intent: "subscription",
				vault: true,
                disableFunding:"paylater,card"
			}}
		>
			<SubscriptionButtonWrapper />
		</PayPalScriptProvider>
		</>
	);
}