import serverIp from "./serverConfig";
let url = `${serverIp}/api/analytics`

const sendAnalytics = (page,action,date) =>{
    try{
        fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Cache-Control':'no-cache',
              'Referrer':`${serverIp}/`,
              // Include the token in the Authorization header
            },
            redirect: "follow", // manual, *follow, error
            // referrerPolicy:"no-referrer-when-downgrade",
            body: JSON.stringify({
                "page":page,
                "action":action,
                "date":date,
                "device":navigator.userAgent
             }), // body data type must match "Content-Type"
          })
    }catch{}
}

export default sendAnalytics;