import React,{Component} from 'react';
import './Prompt.css';
import Logo from '../../images/CASADATA.png';
import sendAnalytics from '../../Functions/analytics';
class PlusMaxPrompt extends Component{
    // constructor(props){
    //     super(props);
    //     this.state={
            
    //     }
    // }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    render(){
        return(
            <section
            onMouseDown={()=>this.props.holdTheMapStill()}
            onTouchStart={()=>this.props.holdTheMapStill()}
            onMouseUp={()=>this.props.releaseTheMap()}
            onTouchEnd={()=>this.props.releaseTheMap()}
            className='promptContainer'>
                <div className='promptInner'>
                    <div className='logoContainer'><img alt="" src={Logo} height='60px' width='65px'></img></div>
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} className='welcomeMessage'>
                        <div>
                            {this.languageIndexer("Brincale alfrente a tu competencia!",'Gain an edge over your competition!')}
                        </div>
                        <div>
                            {this.languageIndexer("Adquiere el poder de ver todas las propiedades reposeidas en todo Puerto Rico con",'Gain the power to view all repossessed properties across Puerto Rico with')}
                        </div>
                        <div className='levelShow' style={{
                        width:85    ,
                        background:'#e1b12c',
                        padding:3,color:'black',
                        fontWeight:700,
                        borderRadius:'5px',
                        height:15,
                        border:'3px solid rgba(0,0,0,.2)'
                        }}>PlusMAX</div></div>
                    <div style={{padding:10}} className='questionText'>
                        {this.languageIndexer("Podras filtrar por fechas para detectar las propiedades reposeidas mas recientes.",'You will be able to filter by dates to detect the most recently repossessed properties.')}
                    </div>
                    <div style={{padding:10}} className='questionText'>
                        {this.languageIndexer("Detecta propiedades reposeídas antes de que sean listadas por los bancos, dándote la ventaja competitiva de actuar rápido.",'Detect repossessed properties before they are listed by banks, giving you the competitive advantage to act quickly.')}
                    </div>
                    <div style={{padding:10}} className='questionText'>
                        {this.languageIndexer("Conoce de antemano qué banco posee cada propiedad y contactalos antes que la informacion se haga publica.",'Know in advance which bank owns each property and contact them before the information becomes public.')}
                    </div>
                    <div className='askButtons'>
                        <button onClick={()=>{
                            this.props.routeToPlusMax()
                            sendAnalytics("PLUSMAXPROMPT","USER ACCEPTED PLUSMAX",String(new Date()))
                            }}>{this.languageIndexer("Mas Detalles",'More Details')}</button>
                        <button style={{background:'rgba(0,0,0,.3)'}} onClick={()=>{
                            this.props.closePlusMaxPrompt()
                            //PAGE,ACTION,DATE
                            sendAnalytics("PLUSMAXPROMPT","USER DECLINED PLUSMAX",String(new Date()))
                            }}>{this.languageIndexer("Volver a Mapa",'Back To Map')}</button>
                    </div>
                </div>
            </section>
        );
    }
}

export default PlusMaxPrompt;